<script>
import { mapState, mapMutations } from 'vuex'
import { fetchFigures } from '@/api'
import { scrollTo } from 'epmc-patterns/helpers'
import { registerMatomoEvent } from '@/helpers/matomo'
import { Loading, Action } from 'epmc-patterns/components/v2'

export default {
  components: { Loading, Action },
  data() {
    return {
      figureDisplaySize: 3,
      imageList: [],
      leftImageIndex: 0,
      activeImage: null,
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    getActiveImageIndex() {
      return this.imageList.findIndex(
        (image) => image.label === this.activeImage.label
      )
    },
  },
  async created() {
    const { fullTextIdList, pmcid, source, id } = this.abstract

    let fulltextId = ''
    if (fullTextIdList) {
      fulltextId = fullTextIdList.fullTextId[0]
    } else if (pmcid) {
      fulltextId = pmcid
    } else if (source === 'PPR') {
      fulltextId = id
    }

    if (fulltextId) {
      try {
        const response = await fetchFigures(fulltextId)
        if (response.fullTextImageList) {
          this.imageList = response.fullTextImageList.fullTextImage
          this.changeSectionVariable({
            id: 'figures-and-tables',
            title: 'Figures ' + '(' + this.imageList.length + ')',
          })
        } else {
          // can we "level up" the rendering
          this.changeSectionVariable({
            id: 'figures-and-tables',
            render: false,
          })
        }
      } catch (err) {
        this.changeSectionVariable({
          id: 'figures-and-tables',
          render: false,
        })
      }
    }
  },
  methods: {
    ...mapMutations('article/sections', ['changeSectionVariable']),
    scrollFigures(inc) {
      this.leftImageIndex += inc
      registerMatomoEvent(
        'Article',
        'Figure preview',
        'Use left/right arrow to view more figures'
      )
    },
    setFullSizeImage(image) {
      this.activeImage = image
      scrollTo('.figures--bar', '.figures--bar')
      registerMatomoEvent(
        'Article',
        'Figure preview',
        'Enlarged figure in preview'
      )
    },
    closeFullSizeImage() {
      this.activeImage = null
    },
    goToImage(inc) {
      this.activeImage = this.imageList[this.getActiveImageIndex + inc]
      scrollTo('.figures--bar', '.figures--bar')
      registerMatomoEvent(
        'Article',
        'Figure preview',
        'Use left/right arrow to view more figures'
      )
    },
    showInFulltext() {
      this.changeSectionVariable({
        id: 'free-full-text',
        visible: true,
      })
      const id = '#' + CSS.escape(this.activeImage.figureId)
      scrollTo(id, id)
    },
  },
}
</script>
<template>
  <loading v-if="!imageList.length" />
  <div v-else class="figures">
    <div
      v-for="i in imageList"
      :key="i.name"
      itemprop="image"
      itemscope
      itemtype="http://schema.org/ImageObject"
    >
      <meta
        itemprop="url"
        :content="
          'https://europepmc.org/articles/' + abstract.pmcid + '/bin/' + i.name
        "
      />
    </div>
    <div :class="['figures--bar', { full: imageList.length === 3 }]">
      <div v-show="leftImageIndex !== 0" class="figure-fade scroll-left">
        <div
          tabindex="0"
          role="button"
          class="figure-scroll-button left"
          @click.prevent="scrollFigures(-1)"
        >
          <span class="fa-stack">
            <i class="far fa-circle fa-stack-1x"></i>
            <i class="fas fa-angle-left fa-stack-1x"></i>
          </span>
          <span class="alt-text">Show more figures</span>
        </div>
      </div>

      <a
        v-for="(image, index) in imageList.slice(
          leftImageIndex,
          leftImageIndex + figureDisplaySize
        )"
        :id="
          'article--figure--icon-' +
          (image.label ? image.label.replace(/[^a-zA-Z\d]/, '-') : index)
        "
        :key="image.label"
        :class="[
          'figures--figure-box',
          { active: activeImage && image.label === activeImage.label },
        ]"
        :style="
          'background-image: url(data:image/jpg;base64,' + image.imageData + ')'
        "
        href="#"
        @click.prevent="setFullSizeImage(image)"
        ><span class="alt-text"
          >({{ image.label }}) Click for full size image</span
        ></a
      >

      <div
        v-show="leftImageIndex + figureDisplaySize < imageList.length"
        class="figure-fade scroll-right"
      >
        <div
          tabindex="0"
          role="button"
          class="figure-scroll-button right"
          @click.prevent="scrollFigures(1)"
        >
          <span class="fa-stack">
            <i class="far fa-circle fa-stack-1x"></i>
            <i class="fas fa-angle-right fa-stack-1x"></i>
          </span>
          <span class="alt-text">Show more figures</span>
        </div>
      </div>
    </div>

    <div v-if="activeImage" class="figures--full-size">
      <div>
        <action
          id="article--figure--fullSizeClose"
          class="figures--full-size-close"
          @click.prevent="closeFullSizeImage"
        >
          <i slot="icon" class="fas fa-times" />
        </action>
      </div>

      <img
        id="article--figure--fullSize"
        :src="'data:image/jpg;base64,' + activeImage.imageData"
        :alt="
          activeImage.caption ||
          'An external file that holds a picture, illustration, etc. Object name is ' +
            activeImage.name
        "
      />

      <div v-if="activeImage.label" class="figures--full-size-label semi-bold">
        {{ activeImage.label }}
      </div>
      <div v-if="activeImage.caption" class="figures--full-size-caption small">
        {{ activeImage.caption }}
      </div>

      <div class="figures--full-size-go">
        <action
          v-show="getActiveImageIndex !== 0"
          @click.prevent="goToImage(-1)"
          ><i slot="icon" class="fas fa-angle-left" />Back</action
        >
        <action icon-pos="right" @click.prevent="showInFulltext"
          >Show in full text<i slot="icon" class="far fa-arrow-alt-circle-down"
        /></action>
        <action
          v-show="getActiveImageIndex !== imageList.length - 1"
          icon-pos="right"
          @click.prevent="goToImage(1)"
          >Next<i slot="icon" class="fas fa-angle-right"
        /></action>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.figures {
  position: relative;
  margin: ($base-unit * 5) auto ($base-unit * 9);

  .figures--bar {
    position: relative;
    margin-right: -($base-unit * 3);
    margin-left: -($base-unit * 3);
    width: calc(100% + calc(#{$base-unit} * 6));
    overflow: hidden;
    white-space: nowrap;

    .figure-fade {
      position: absolute;
      top: 0;
      z-index: 1;
      width: $base-unit * 13;
      height: 100%;
      &.scroll-left {
        left: -0;
        background: $epmc-white;
        background: -moz-linear-gradient(
          268deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 16%,
          rgba(255, 255, 255, 1) 70%
        );
        background: -webkit-linear-gradient(
          268deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 16%,
          rgba(255, 255, 255, 1) 70%
        );
        background: linear-gradient(
          268deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 16%,
          rgba(255, 255, 255, 1) 70%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
      }
      &.scroll-right {
        right: 0;
        background: $epmc-white;
        background: -moz-linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 16%,
          rgba(255, 255, 255, 1) 70%
        );
        background: -webkit-linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 16%,
          rgba(255, 255, 255, 1) 70%
        );
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 16%,
          rgba(255, 255, 255, 1) 70%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
      }
      .figure-scroll-button {
        cursor: pointer;
        position: absolute;
        top: 0;
        height: 100%;
        width: $base-unit * 10;
        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          height: 100%;
        }
        .fa-stack {
          vertical-align: baseline;
          font-size: $base-unit * 7;
          width: $base-unit * 7.5;
          height: $base-unit * 7;
          color: lighten($epmc-darker-grey, 30%);
          .fa-circle {
            color: transparent;
          }
        }
        &:focus {
          outline: 0;
          .fa-stack,
          .fa-circle {
            color: $epmc-darker-blue;
          }
        }
        &:hover {
          .fa-stack {
            color: $epmc-dark-blue;
          }
        }
        &:active {
          .fa-stack,
          .fa-circle {
            color: $epmc-dark-green;
          }
        }
        &.right {
          right: 0;
        }
        &.left {
          left: 0;
        }
      }
    }

    .figures--figure-box {
      display: inline-block;
      margin: 0 ($base-unit * 1.5);
      border: $base-unit solid lighten($epmc-darker-grey, 30%);
      width: 33%;
      height: 0;
      background-size: cover;
      padding-bottom: 28%;
      &:first-of-type {
        margin-left: $base-unit * 3;
      }
      &.active {
        border-color: $epmc-darker-blue;
      }
      &:hover {
        border-color: $epmc-dark-blue;
      }
    }
    &.full .figures--figure-box {
      width: 30%;
    }
  }

  .figures--full-size {
    margin-top: $base-unit * 8;
    text-align: center;
    .figures--full-size-close {
      float: right;
      font-size: $base-unit * 6;
      i {
        color: $epmc-darker-grey;
      }
    }
    img {
      margin: $base-unit auto ($base-unit * 4);
    }
    .figures--full-size-label {
      margin-bottom: $base-unit * 2;
      text-align: left;
    }
    .figures--full-size-caption {
      margin-bottom: $base-unit * 2;
      text-align: left;
    }
    .figures--full-size-go {
      margin: ($base-unit * 3) auto;
      & > * {
        vertical-align: top;
      }
      & > *:first-child {
        float: left;
      }
      & > *:last-child {
        float: right;
      }
    }
  }
}
</style>
