<script>
import ViewStructure from './ViewStructure'

export default {
  components: {
    ViewStructure,
  },
  props: {
    link: {
      type: Object,
      default: () => {},
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<template>
  <li class="data-list-item">
    <span v-if="link.img" class="img-container">
      <a :href="link.url" class="small" :aria-label="'Link for ' + link.id">
        <img class="img-size" :src="link.img" :alt="'Link for ' + link.id" />
      </a>
    </span>
    <span class="data-container">
      <span v-if="link.frequency" class="no-link">
        ({{ link.frequency }} citation{{ link.frequency != 1 ? 's' : '' }})
      </span>
      <a class="data-link" :href="link.url">
        <span class="url">{{ link.description }}</span>
        <i class="fas fa-external-link-alt icon" />
      </a>
      <span v-if="link.ObtainedBy === 'submission'">{{
        '(' +
        link.scheme +
        ' - ' +
        (link.scheme === 'CHEBI' ? link.id : link.id.replace(':', '')) +
        ')'
      }}</span>
      <view-structure
        v-if="link.scheme === 'PDB' || link.scheme === 'CHEBI'"
        :link-id="link.id"
        :link-scheme="link.scheme"
        :expand-all="expandAll"
      />
    </span>
  </li>
</template>
<style scoped lang="scss">
.data-list-item {
  margin: 0;
  overflow: auto;
  border: thin solid lighten($epmc-darker-grey, 64%);
  &:nth-child(odd) {
    background-color: lighten($epmc-darker-grey, 64%);
  }
}
.no-link {
  float: right;
  margin-left: $base-unit * 2;
}
.img-container {
  float: left;
  margin-right: $base-unit * 2.5;
  height: $base-unit * 18;
  background-color: $epmc-white;
}
.data-container {
  padding: $base-unit * 2.5;
  display: list-item;
}
.data-link {
  display: flex;
  align-items: center;
  i {
    margin-left: $base-unit * 2;
  }
  span {
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.img-size {
  height: $base-unit * 16;
  width: $base-unit * 16;
}
</style>
