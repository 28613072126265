<script>
import { mapState, mapMutations } from 'vuex'
import { fetchArticles, fetchSimilarArticles } from '@/api'
import { List } from 'epmc-patterns/components/v2'
import Citation from '@/templates/Citation'

export default {
  components: { Citation, List },
  data() {
    return {
      similarLinks: [],
    }
  },
  computed: mapState('article/abstract', ['abstract']),
  created() {
    fetchSimilarArticles(this.abstract.pmid).then((response) => {
      const links = response.linksets[0].linksetdbs[0].links.slice(1)
      if (links.length > 0) {
        const articles = links.map((link) => ({
          id: link,
          source: 'MED',
        }))
        fetchArticles({ articles }).then((response) => {
          if (response.resultList) {
            this.similarLinks = links
              .map((link) =>
                response.resultList.result.find(
                  (citation) => citation.id === link
                )
              )
              .filter((link) => link)
          }
        })
      } else {
        this.changeSectionVariable({
          id: 'similar-articles',
          render: false,
        })
      }
    })
  },
  methods: mapMutations('article/sections', ['changeSectionVariable']),
}
</script>
<template>
  <div>
    <p class="small italic">
      To arrive at the top five similar articles we use a word-weighted
      algorithm to compare words from the Title and Abstract of each citation.
    </p>
    <list :list="similarLinks" separator-below>
      <citation slot-scope="{ item }" :citation="item" />
    </list>
  </div>
</template>
