<script>
import { mapState, mapActions } from 'vuex'
import { fetchAuthorProfileList, fetchHypothesis } from '@/api'
import {
  getDate,
  containEmail,
  stripEmail,
  scrollTo,
} from 'epmc-patterns/helpers'
import { addLink, addScript } from '@/helpers/load-script-and-style'
import { registerMatomoEvent } from '@/helpers/matomo'
import { isStopWord } from 'js-solr-highlighter'
import MetadataAuthor from './MetadataAuthor'
import MetadataAuthorPopup from './MetadataAuthorPopup'
import CommentsAndCorrections from './CommentsAndCorrections'
import {
  Action,
  SharePanel,
  Notification,
  Tooltip,
} from 'epmc-patterns/components/v2'
import Labels from '@/templates/Labels'
import { formatDoi } from '@/helpers/doi'
import { convertLatexToMathMl, stripMMLPrefix } from '@/helpers/latex'

const capitaliseNonStopWords = (string) => {
  return string
    .split(' ')
    .map((word) => {
      if (
        isStopWord(word) ||
        word == word.toUpperCase() ||
        word.length <= 3 ||
        word[0] === '(' ||
        word[0] === '.' ||
        word.includes('.') ||
        word[0] === '['
      ) {
        return word
      }
      word = word.toLowerCase()
      return word[0].toUpperCase() + word.slice(1)
    })
    .join(' ')
}

function limitAuthors() {
  this.displayedAuthors =
    this.authorsCount <= this.maxNumberOfAuthorsShown
      ? this.allAuthors
      : this.allAuthors.slice(0, this.maxNumberOfAuthorsShown)
}

function createMetadata() {
  const metadata = [
    {
      name: 'citation_language',
      content: 'English',
    },
    {
      name: 'citation_abstract_html_url',
      content: window.location.href,
    },
  ]
  if (this.abstract) {
    if (this.abstract.pmid) {
      metadata.push({
        name: 'citation_pmid',
        content: this.abstract.pmid,
      })
    }
    if (this.abstract.pmcid) {
      metadata.push({
        name: 'citation_pmcid',
        content: this.abstract.pmcid,
      })
    }
    if (this.abstract.doi) {
      metadata.push({
        name: 'citation_doi',
        content: this.abstract.doi,
      })
    }
    if (this.abstract.title) {
      metadata.push({
        name: 'citation_title',
        content: this.abstract.title,
      })
    }
    if (this.abstract.title) {
      metadata.push({
        name: 'citation_title',
        content: this.abstract.title,
      })
    }
    if (this.abstract.journalInfo) {
      if (this.abstract.journalInfo.journal) {
        metadata.push({
          name: 'citation_journal_title',
          content: this.abstract.journalInfo.journal.title,
        })
        metadata.push({
          name: 'citation_journal_abbrev',
          content:
            this.abstract.journalInfo.journal.medlineAbbreviation ||
            this.abstract.journalInfo.journal.ISOAbbreviation,
        })
        if (this.abstract.journalInfo.journal.ISSN) {
          metadata.push({
            name: 'citation_issn',
            content: this.abstract.journalInfo.journal.ISSN,
          })
        }
        if (this.abstract.journalInfo.journal.ESSN) {
          metadata.push({
            name: 'citation_issn',
            content: this.abstract.journalInfo.journal.ESSN,
          })
        }
      }
      if (this.abstract.journalInfo.volume) {
        metadata.push({
          name: 'citation_volume',
          content: this.abstract.journalInfo.volume,
        })
      }
      if (this.abstract.journalInfo.issue) {
        metadata.push({
          name: 'citation_issue',
          content: this.abstract.journalInfo.issue,
        })
      }
    }
    /*  
    citation_publisher
    citation_isbn
    citation_dissertation_institution
    */
    if (this.abstract.firstPublicationDate) {
      metadata.push({
        name: 'citation_publication_date',
        content: this.abstract.firstPublicationDate.replace(/-/gi, '/'),
      })
    }
    if (this.abstract.pageInfo) {
      const pages = this.abstract.pageInfo.split('-')
      metadata.push({
        name: 'citation_firstpage',
        content: pages[0],
      })
      metadata.push({
        name: 'citation_lastpage',
        content: pages[1] || pages[0],
      })
    }
    if (this.abstract.keywordList && this.abstract.keywordList.keyword) {
      metadata.push({
        name: 'citation_keywords',
        content: this.abstract.keywordList.keyword.join('; '),
      })
    }
    //1. Author name metatags MUST be in the order the authors occur in the publication.
    //2. The citation_author_institution and citation_author_email tags for an author should beright after the author they correspond to and before another author or a non-author metatag.
    if (this.abstract.authorList && this.abstract.authorList.author) {
      this.abstract.authorList.author.map((a) => {
        metadata.push({
          name: 'citation_author',
          content: a.fullName,
        })
        if (a.affiliation) {
          const email = containEmail(a.affiliation)
          if (email) {
            metadata.push({
              name: 'citation_author_email',
              content: email,
            })
            metadata.push({
              name: 'citation_author_institution',
              content: stripEmail(a.affiliation),
            })
          } else {
            metadata.push({
              name: 'citation_author_institution',
              content: a.affiliation,
            })
          }
        }
      })
    } else if (this.abstract.authorString) {
      metadata.push({
        name: 'citation_authors',
        content: this.abstract.authorString,
      })
    }
    if (
      this.abstract.fullTextUrlList &&
      this.abstract.fullTextUrlList.fullTextUrl
    ) {
      const pdf = this.abstract.fullTextUrlList.fullTextUrl.find(
        (l) => l.documentStyle === 'pdf' && l.site === 'Europe_PMC'
      )
      const html = this.abstract.fullTextUrlList.fullTextUrl.find(
        (l) => l.documentStyle === 'html'
      )
      if (pdf) {
        metadata.push({
          name: 'citation_pdf_url',
          content: pdf.url,
        })
      }
      if (html) {
        metadata.push({
          name: 'citation_fulltext_html_url',
          content: html.url,
        })
      }
    }
  }
  return metadata
}

export default {
  components: {
    Action,
    MetadataAuthor,
    CommentsAndCorrections,
    Notification,
    SharePanel,
    Labels,
    Tooltip,
    MetadataAuthorPopup,
  },
  props: {
    maxNumberOfAuthorsShown: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      affiliations: [],
      orcids: [],
      lastAuthor: '',
      allAuthors: [],
      authorsCount: 0,
      hypothesisData: {},
      showHypothesis: false,
      displayedAuthors: [],
      shareURL: '',
      showRemAuthors: false,
      showAffs: false,
      showCollabs: false,
      showPreprintVer: true,
      scrolled: 0,
      hideHypothesisPanel: true,
      showMetadataAuthorPopup: false,
      selectedOrcid: null,
      showAllAffiliations: false,
      showAllOrcids: false,
      mathMlConversionLoading: false,
      latexTitleUpdated: false,
      mathMlTagUpdated: false,
      triggerLatexConversion: false,
    }
  },
  metaInfo() {
    return {
      meta: createMetadata.call(this),
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('article/sections', ['sections']),
    articleTitle() {
      const { title } = this.abstract
      let newTitle = title.length > 110 ? title.substring(0, 110) : title
      newTitle += title.length > 110 ? title.substring(110) : ''
      return newTitle
    },
    hasNotificationList() {
      return this.abstract.commentCorrectionList !== undefined
    },
    notificationList() {
      return this.abstract.commentCorrectionList.commentCorrection
    },
    hypothesisAction() {
      let action = {}

      if (this.showHypothesis && this.size !== 'mobile') {
        action = {
          id: 'get_hypothesis',
          text:
            'PubMed Commons archived comments (' +
            this.hypothesisData.total +
            ')',
          iconClass: 'fas fa-comments',
          onClick: () => this.onActionClicked('get_hypothesis'),
          tooltip: `PubMed Commons was established by the NLM (National Library of Medicine) to enable users of PubMed to comment on articles. PubMed Commons was closed in February 2018 and an archive was created by Hypothes.is. Europe PMC has partnered with Hypothes.is to display PubMed Commons comments on relevant articles.`,
        }
      }
      return action
    },
    showFreeToReadLabelTooltip() {
      return window.location.search.indexOf('client=bot') === -1
    },
    formatDoi() {
      return formatDoi(this.abstract.doi)
    },
  },
  mounted() {
    this.triggerLatexConversion = this.abstract.containsLatex
    this.mathMlConversionLoading = true
  },
  updated() {
    if (this.triggerLatexConversion && !this.latexTitleUpdated) {
      const latexString = this.abstract.title
      convertLatexToMathMl(latexString)
        .then((updatedString) => {
          this.updateAbstractTitle({ title: updatedString })
          this.latexTitleUpdated = true // Update the flag
          this.mathMlConversionLoading = false
        })
        .catch(() => {
          this.mathMlConversionLoading = false // Handle error if needed
        })
    } else {
      if (!this.mathMlTagUpdated) {
        const updatedString = stripMMLPrefix(this.abstract.title)
        this.updateAbstractTitle({ title: updatedString })
        this.mathMlTagUpdated = true
      }
      this.mathMlConversionLoading = false
    }
  },
  created() {
    this.shareURL = window.location.href
    const { abstract } = this
    if (abstract.authorList) {
      this.authorsCount = abstract.authorList.author.length
      this.allAuthors = abstract.authorList.author.map((a) => {
        if (
          !a.firstName &&
          !a.fullName &&
          !abstract.authorString &&
          a.fullName.replace(/[^\w\s]|_/g, '') ===
            abstract.authorString.replace(/[^\w\s]|_/g, '')
        ) {
          a.collectiveName = a.fullName
        }
        return a
      })
      this.lastAuthor = this.allAuthors[this.authorsCount - 1]
      limitAuthors.call(this)

      const affs = new Set(
        abstract.authorList.author.map((a) => stripEmail(a.affiliation))
      )
      if (abstract.investigatorList) {
        ;[
          ...new Set(
            abstract.investigatorList.investigator.map((a) => {
              a.isInvestigator = true
              stripEmail(a.affiliation)
            })
          ),
        ].forEach((a) => {
          affs.add(a)
        })
      }
      ;[...affs].forEach((a) => {
        if (a) {
          const aff = {}
          aff.name = a
          aff.focused = false
          this.affiliations.push(aff)
        }
      })
      // expand affiliation section in case of rendertron
      this.showAffs = abstract.botRequest
      this.showCollabs = abstract.botRequest
      if (abstract.botRequest) this.loadRestOfAuthors()
      else {
        this.loadPubmedCommons()
      }
    }
    //format journal title
    if (abstract.journalInfo) {
      abstract.journalInfo.journal.title = capitaliseNonStopWords(
        abstract.journalInfo.journal.title
      )
    }
    if (abstract.authorIdList) {
      const orcidObj = abstract.authorIdList.authorId.map((a) => a.value)
      const authorArr = abstract.authorList.author.concat(
        abstract.investigatorList ? abstract.investigatorList.investigator : {}
      )
      const orcids = []

      fetchAuthorProfileList(orcidObj).then((result) => {
        orcidObj.forEach((a) => {
          if (a) {
            const orcid = {}
            orcid.value = a
            const found = authorArr.find((x) => {
              if (x.authorId && x.authorId.value === a) return true
            })
            orcid.authorname = found ? found.fullName : ''
            orcid.authorAffiliationDetailsList = found
              ? found.authorAffiliationDetailsList
              : undefined
            orcid.authorId = found ? found.authorId : undefined
            const orcidProfile = result.find((profile) => a === profile.orcid)
            if (orcidProfile) {
              orcid.affiliation = orcidProfile.affiliation
              orcid.authorname = orcid.authorname
                ? orcid.authorname
                : orcidProfile.familyName
                ? `${orcidProfile.familyName} ${orcidProfile.givenNames
                    .split(' ')
                    .map(function (item) {
                      return item[0]
                    })
                    .join('')}`
                : ''
            }
            orcids.push(orcid)
          }
        })
      })

      this.orcids = orcids.sort((a, b) => {
        if (a.authorname === '') return 1
        else if (b.authorname === '') return -1
        else
          return a.authorname < b.authorname
            ? -1
            : a.authorname > b.authorname
            ? 1
            : 0
      })
    }
  },
  beforeDestroy() {
    if (
      document.getElementsByClassName('popup-shadow') &&
      document.getElementsByClassName('popup-shadow').length > 0
    ) {
      document.getElementsByClassName('popup-shadow')[0].outerHTML = ''
    }
  },
  methods: {
    ...mapActions('article/abstract', ['updateAbstractTitle']),
    registerMatomoEvent,
    getDate,
    affCount(aff) {
      return this.abstract.authorList.author
        .concat(
          this.abstract.investigatorList
            ? this.abstract.investigatorList.investigator
            : ''
        )
        .filter(
          (auth) =>
            auth.authorAffiliationDetailsList &&
            auth.authorAffiliationDetailsList.authorAffiliation &&
            auth.authorAffiliationDetailsList.authorAffiliation.find(
              (i) => stripEmail(i.affiliation) === aff
            )
        ).length
    },
    getAuthorsLinkedToAffiliation(aff) {
      return this.abstract.authorList.author
        .concat(
          this.abstract.investigatorList
            ? this.abstract.investigatorList.investigator
            : ''
        )
        .filter(
          (auth) =>
            auth.authorAffiliationDetailsList &&
            auth.authorAffiliationDetailsList.authorAffiliation &&
            auth.authorAffiliationDetailsList.authorAffiliation.find(
              (i) => stripEmail(i.affiliation) === aff
            )
        )
    },
    getAffiliationsByAuthor(currentIndex, affAuthor) {
      const { affiliations } = this
      let a = false
      for (let index = currentIndex; index < affiliations.length; index++) {
        const element = affiliations[index + 1]
        if (element)
          a = affAuthor.authorAffiliationDetailsList.authorAffiliation.find(
            (item) => stripEmail(item.affiliation) === element.name
          )
        if (a) return ','
      }
    },
    removeEmailFromAffiliation(affiliation) {
      return stripEmail(affiliation)
    },
    toggleAffs() {
      this.showAffs = !this.showAffs
      if (this.showAffs === true) {
        this.registerMatomoEventForAuthorInformation('Expanded')
      }
    },
    toggleCollabs() {
      this.showCollabs = !this.showCollabs
    },
    loadRestOfAuthors() {
      this.showRemAuthors = true
      this.displayedAuthors = this.allAuthors
    },
    showLessAuthors() {
      this.showRemAuthors = false
      limitAuthors.call(this)
      scrollTo(
        '#article-metadata-title',
        '#article-metadata-title div:first-child'
      )
    },
    isFutureDate(date) {
      return new Date() < new Date(date)
    },
    registerMatomoEventForAuthorInformation(eventName) {
      registerMatomoEvent('Article', 'Author information', eventName)
    },
    registerMatomoEventForArticleInformation(eventName) {
      registerMatomoEvent('Article', 'Article information', eventName)
    },
    registerMatomoEventForSocialShare(socialName) {
      registerMatomoEvent('Article', 'Social share', socialName)
    },
    registerMatomoEventForCommentsAndCorrection(eventName) {
      registerMatomoEvent('Article', 'Comments corrections', eventName)
    },
    registerMatomoEventForPreprintReviews(eventName) {
      registerMatomoEvent(
        'Article',
        'Preprint version history',
        'Link to ' + eventName + ' for a version'
      )
    },
    onActionClicked(actionId) {
      const { abstract } = this

      if (actionId === 'get_hypothesis') {
        if (!window.hypothesisConfig) {
          addLink({
            href: '/abstract/' + abstract.source + '/' + abstract.id,
            rel: 'alternate',
          })

          const alertFunc = () => {
            alert(
              'It is not possible to sign up / log in to add an annotation or page note to articles in Europe PMC. Only archived PubMed Commons comments are currently displayed on articles. If you would like to annotate or comment on an article, please let us know using our Feedback form.'
            )
          }
          window.hypothesisConfig = () => {
            return {
              openSidebar: true,
              theme: 'clean',
              externalContainerSelector: '#hypothesis-panel',
              services: [
                {
                  apiUrl: 'https://hypothes.is/api/',
                  authority: null,
                  grantToken: null,
                  onLoginRequest: alertFunc,
                  onSignupRequest: alertFunc,
                },
              ],
              onLayoutChange: (obj) => {
                if (obj.expanded) {
                  this.hideHypothesisPanel = false
                }
              },
            }
          }

          addScript({
            pos: document.body,
            src: 'https://hypothes.is/embed.js',
            async: true,
          })
        } else {
          this.hideHypothesisPanel = false
        }
      }

      if (actionId === 'get_hypothesis') {
        const actionVal = this.hypothesisAction
        registerMatomoEvent('Article', 'Tools', actionVal.text)
      }
    },
    // just a wrapper method to be mocked by the unit test
    wrapperFetchHypothesis(pmid) {
      return fetchHypothesis(pmid)
    },
    loadPubmedCommons() {
      if (this.abstract != null && this.abstract.source === 'MED') {
        this.wrapperFetchHypothesis(this.abstract.pmid).then((response) => {
          if (response.total && response.total > 0) {
            this.hypothesisData = response
            this.showHypothesis = true
          }
        })
      }
    },
    openMetadataAuthorPopup(orcid) {
      this.registerMatomoEventForAuthorInformation('Author profile')
      this.selectedOrcid = orcid
      this.showMetadataAuthorPopup = true
    },
    expand(e, secid, boolean) {
      const sec = this.sections.find((sec) => sec.id === secid)
      sec.visible = boolean
      scrollTo('#' + secid, '#' + secid + ' div:first-child')
      history.pushState(null, null, '#' + secid)
      this.sections.forEach((sec) => {
        sec.inFrame = false
      })
      sec.inFrame = true
    },
  },
}
</script>
<template>
  <div v-if="!mathMlConversionLoading" class="article-metadata small">
    <div
      itemprop="publisher"
      itemscope
      itemtype="http://schema.org/Organization"
    >
      <meta itemprop="name" content="Europe PMC" />
      <div itemprop="logo" itemscope itemtype="http://schema.org/ImageObject">
        <meta
          itemprop="url"
          content="http://europepmc.org/template/images/epmc-logo-single.png"
        />
      </div>
    </div>
    <h1
      v-if="abstract.title.trim() === '' || !abstract.title"
      id="article--current--title"
      class="article-metadata-title metadata--article-no-title"
      itemprop="headline"
    >
      Title not supplied
    </h1>
    <h1
      v-else
      id="article--current--title"
      class="article-metadata-title"
      itemprop="name"
      v-html="articleTitle"
    />
    <div v-if="abstract.authorList" class="metadata--authors">
      <div class="metadata--author-list">
        <template v-for="(author, index) in displayedAuthors">
          <metadata-author
            :key="index"
            class="metadata--author"
            :affiliations="affiliations"
            :author="author"
            :last="index === displayedAuthors.length - 1"
          />&#x20;
        </template>
        <template v-if="displayedAuthors.length < allAuthors.length">
          <span class="metadata--author-showall-link">
            ...
            <a href="#" class="semi-bold" @click.prevent="loadRestOfAuthors()">
              [Show all {{ allAuthors.length }}]
            </a>
            ...
          </span>
          <metadata-author
            :key="allAuthors.length - 1"
            class="metadata--author"
            :affiliations="affiliations"
            :author="lastAuthor"
            :last="true"
          />
        </template>
        <template v-else-if="allAuthors.length > maxNumberOfAuthorsShown">
          <div class="metadata--author-showall-link">
            <hr />
            <action icon-pos="right" @click="showLessAuthors()">
              Show less<i slot="icon" class="fas fa-caret-up icon" />
            </action>
          </div>
        </template>
      </div>

      <template v-if="abstract.investigatorList">
        <h2 class="metadata--dropdown-title">
          <action
            id="article--expand--collaborators"
            icon-pos="right"
            @click="toggleCollabs"
          >
            Collaborators ({{
              abstract.investigatorList.investigator.length
            }})<i
              slot="icon"
              :class="[
                'fas icon',
                showCollabs ? 'fa-caret-down' : 'fa-caret-right',
              ]"
            />
          </action>
        </h2>
        <div v-show="showCollabs" class="metadata--collab-list">
          <template
            v-for="(collab, index) in abstract.investigatorList.investigator"
          >
            <metadata-author
              :key="index"
              :affiliations="affiliations"
              :author="collab"
              :last="
                index === abstract.investigatorList.investigator.length - 1
              "
            />
            &#x20;
          </template>
        </div>
      </template>
      <template
        v-if="
          affiliations.length > 0 || abstract.affiliation || orcids.length > 0
        "
      >
        <h2 class="metadata--dropdown-title">
          <action
            id="article--expand--authInformation"
            icon-pos="right"
            @click="toggleAffs"
          >
            Author information<i
              slot="icon"
              :class="['fas', showAffs ? 'fa-caret-down' : 'fa-caret-right']"
            />
          </action>
        </h2>
        <div v-show="showAffs" class="metadata--author-information">
          <template v-if="affiliations.length > 0 || abstract.affiliation">
            <h3>Affiliations</h3>
            <ul class="metadata--author-affiliation-list">
              <li
                v-for="(affiliation, index) in showAllAffiliations
                  ? affiliations
                  : affiliations.slice(0, 5)"
                :id="`article--affiliation--text-` + index"
                :key="index"
                :class="[
                  'metadata--author-affiliation-item',
                  { focused: affiliation.focused },
                ]"
                tabindex="0"
                role="button"
              >
                <div class="metadata--author-affiliation-text">
                  <span class="metadata--author-affiliation-index"
                    >{{ index + 1 }}.</span
                  >
                  {{
                    affiliation.name.match(/^[a-z]\s/)
                      ? affiliation.name.substring(2)
                      : affiliation.name
                  }}
                  <tooltip trigger="click" placement="bottom" max-width="50vw">
                    <template slot="trigger">
                      <span class="metadata--authors-count"
                        >({{ affCount(affiliation.name) }} author{{
                          affCount(affiliation.name) > 1 ? 's' : ''
                        }})</span
                      >
                    </template>
                    <ul class="metadata--author-affiliation-author-name">
                      <h6 style="font-style: normal">Authors</h6>
                      <li
                        v-for="affAuthor in getAuthorsLinkedToAffiliation(
                          affiliation.name
                        )"
                        :key="affAuthor.fullName"
                      >
                        <h6
                          v-if="affAuthor.isInvestigator"
                          class="metadata--contributors-heading"
                        >
                          Contributors
                        </h6>
                        <span> {{ affAuthor.fullName }}</span>
                        <sup style="text-indent: 5px">
                          <span
                            v-for="(aff, idx) in affiliations"
                            :key="idx"
                            style="display: inline-flex"
                          >
                            <span
                              v-if="
                                affAuthor.authorAffiliationDetailsList.authorAffiliation.find(
                                  (item) =>
                                    removeEmailFromAffiliation(
                                      item.affiliation
                                    ) === aff.name
                                )
                              "
                              class="book-tooltip"
                            >
                              {{ idx + 1
                              }}{{ getAffiliationsByAuthor(idx, affAuthor) }}
                            </span>
                          </span>
                        </sup>
                      </li>
                    </ul>
                  </tooltip>
                </div>
              </li>
              <li
                v-if="affiliations.length === 0 && abstract.affiliation"
                class="metadata--author-affiliation-item no-focus"
              >
                <span class="metadata--authors-count">All authors</span>
                <div class="metadata--author-affiliation-text">
                  <span class="metadata--author-affiliation-index">1.</span>
                  {{
                    abstract.affiliation.match(/^[a-z]\s/)
                      ? abstract.affiliation.substring(2)
                      : abstract.affiliation
                  }}
                </div>
              </li>
              <template v-if="affiliations.length > 5">
                <a
                  style="font-weight: bold"
                  @click.prevent="showAllAffiliations = !showAllAffiliations"
                >
                  <span v-if="showAllAffiliations"> Show less </span>
                  <span v-else> Show all ({{ affiliations.length }}) </span>
                  <i
                    slot="icon"
                    :class="[
                      'fas',
                      showAllAffiliations ? 'fa-caret-down' : 'fa-caret-right',
                    ]"
                  />
                </a>
              </template>
            </ul>
          </template>
          <template v-if="orcids.length > 0">
            <h3>ORCIDs linked to this article</h3>
            <ul class="metadata--author-orcid-list">
              <li
                v-for="orcid in showAllOrcids ? orcids : orcids.slice(0, 5)"
                :id="'article--ocid--text-' + orcid.value"
                :key="orcid.value"
                class="metadata--author-orcid-item"
              >
                <a
                  :to="{
                    name: 'authorProfile',
                    params: { orcid: orcid.value },
                  }"
                  @click="openMetadataAuthorPopup(orcid)"
                >
                  <span v-if="orcid.authorname">{{ orcid.authorname }}</span>
                </a>
                |
                <action
                  :href="'//orcid.org/' + orcid.value"
                  class="orcid-link"
                  @click="registerMatomoEventForAuthorPopup('External ORCID')"
                  ><i class="fab fa-orcid" />{{ orcid.value }}
                  <i
                    style="font-size: 12px"
                    class="fas fa-external-link-alt"
                  ></i
                ></action>
                <!-- <span v-if="orcid.affiliation">, {{ orcid.affiliation }}</span> -->
              </li>
              <metadata-author-popup
                v-if="showMetadataAuthorPopup"
                :author="selectedOrcid"
                :author-name="selectedOrcid.authorname"
                @close="showMetadataAuthorPopup = false"
              />
            </ul>
            <template v-if="orcids.length > 5">
              <a
                style="font-weight: bold"
                @click.prevent="showAllOrcids = !showAllOrcids"
              >
                <span v-if="showAllOrcids"> Show less </span>
                <span v-else> Show all ({{ orcids.length }}) </span>
                <i
                  slot="icon"
                  :class="[
                    'fas',
                    showAllOrcids ? 'fa-caret-down' : 'fa-caret-right',
                  ]"
                />
              </a>
            </template>
          </template>
        </div>
      </template>
    </div>

    <p class="metadata--citation">
      <template v-if="abstract.bookOrReportDetails || abstract.journalInfo">
        <span
          v-if="
            abstract.bookOrReportDetails && abstract.bookOrReportDetails.edition
          "
          class="metadata--edition"
          >{{ abstract.bookOrReportDetails.edition }} edition<br
        /></span>
        {{
          abstract.bookOrReportDetails && abstract.pubTypeList
            ? abstract.pubTypeList.pubType[0] + ' from'
            : ''
        }}
        <span
          id="article--publisher--name"
          class="metadata--source-title"
          :itemprop="abstract.journalInfo ? 'isPartOf' : 'publisher'"
          itemscope
          :itemtype="
            abstract.journalInfo
              ? 'http://schema.org/Periodical'
              : 'http://schema.org/Organization'
          "
          ><span itemprop="name">{{
            (abstract.journalInfo &&
              (['BMJ', 'Science'].includes(
                abstract.journalInfo.journal.medlineAbbreviation
              )
                ? abstract.journalInfo.journal.medlineAbbreviation
                : abstract.journalInfo.journal.title)) ||
            (abstract.bookOrReportDetails &&
              abstract.bookOrReportDetails.publisher) ||
            (abstract.bookOrReportDetails &&
              abstract.bookOrReportDetails.comprisingTitle) ||
            ''
          }}</span></span
        >,
      </template>
      <template v-else-if="abstract.patentDetails">
        <span
          v-if="
            abstract.patentDetails.typeDescription ||
            abstract.patentDetails.country
          "
          class="metadata--meta-title"
          >Patent:
        </span>
        {{ abstract.patentDetails.typeDescription }}
        {{
          abstract.patentDetails.typeDescription &&
          abstract.patentDetails.country
            ? '-'
            : ''
        }}
        {{ abstract.patentDetails.country }}<br />
        <span class="metadata--meta-title">Application: </span
        >{{ abstract.patentDetails.application.applicationNumber }} on
        {{ getDate(abstract.patentDetails.application.applicationDate) }}<br />
        <span class="metadata--meta-title">Publication: </span>
      </template>
      <span itemprop="datepublished">{{
        getDate(abstract.firstPublicationDate)
      }}</span
      >{{
        abstract.journalInfo && Object.keys(abstract.journalInfo).length > 0
          ? ', '
          : ' '
      }}{{
        abstract.journalInfo && abstract.journalInfo.volume
          ? abstract.journalInfo.volume
          : ''
      }}{{
        abstract.journalInfo && abstract.journalInfo.issue
          ? '(' + abstract.journalInfo.issue + ')'
          : ''
      }}{{ abstract.journalInfo && abstract.pageInfo ? ':' : ''
      }}<span
        :itemprop="
          abstract.bookOrReportDetails ? 'numberOfPages' : 'pagination'
        "
        >{{ abstract.pageInfo ? abstract.pageInfo : '' }}</span
      >
      <template v-if="abstract.language && abstract.language !== 'eng'">
        <span class="metadata--language">Language:</span>
        <span class="metadata--language-code">{{ abstract.language }}</span>
      </template>
      <br /><span v-if="abstract.doi" class="metadata--doi">
        <a
          id="article--doi--link-metadataSec"
          :href="'//doi.org/' + abstract.doi"
          @click="registerMatomoEventForArticleInformation('DOI')"
          >{{ formatDoi }}</a
        >&nbsp;</span
      >
      <span v-if="abstract.pmid" class="metadata--pmid"
        >PMID: {{ abstract.pmid }}&nbsp;</span
      >
      <span
        v-else-if="abstract.source !== 'PMC'"
        id="article--pmid"
        class="metadata--id"
        >{{ abstract.source }}: {{ abstract.id }}&nbsp;</span
      >
      <span v-if="abstract.pmcid" id="article--pmcid" class="metadata--pmcid"
        >PMCID: {{ abstract.pmcid }}</span
      >
    </p>
    <labels :citation="abstract" class="metadata--labels" page="Article">
      <template slot="label-slot">
        <slot name="label-slot" />
      </template>
    </labels>

    <div
      v-if="hasNotificationList"
      id="article--comments--notifications"
      class="notificationList"
    >
      <comments-and-corrections
        :comment-correction-list="notificationList"
        :show="1"
        :allowed-nofitication-styles="['error']"
        @shownAll="
          () => registerMatomoEventForCommentsAndCorrection('Show more')
        "
      />
      <comments-and-corrections
        :comment-correction-list="notificationList"
        :show="1"
        :allowed-nofitication-styles="['warning']"
        @shownAll="
          () => registerMatomoEventForCommentsAndCorrection('Show more')
        "
      />
      <comments-and-corrections
        :comment-correction-list="notificationList"
        :allowed-nofitication-styles="['informative']"
        @shownAll="
          () => registerMatomoEventForCommentsAndCorrection('Show more')
        "
      />
    </div>
    <notification
      v-if="!abstract.hasFulltext && isFutureDate(abstract.embargoDate)"
      id="article--comments--notifications-embargoDate"
      class="notificationList"
      notification-style="informative"
    >
      Free full text available after {{ getDate(abstract.embargoDate) }}
    </notification>
    <div v-if="abstract.versionList" class="preprint-ver">
      <h2 class="metadata--dropdown-title">
        <action icon-pos="right" @click="showPreprintVer = !showPreprintVer">
          Preprint version history<i
            slot="icon"
            :class="[
              'fas',
              showPreprintVer ? 'fa-caret-down' : 'fa-caret-right',
            ]"
          />
        </action>
      </h2>
      <ul v-show="showPreprintVer">
        <li
          v-for="ver in abstract.versionList.version.slice().reverse()"
          :key="ver.versionNumber"
        >
          <router-link
            v-if="ver.versionNumber !== abstract.versionNumber"
            :to="{
              name: 'article',
              params: {
                source: ver.source,
                id: ver.id,
                matomo_event: {
                  category: 'Article',
                  action: 'Preprint version history',
                  name: 'Link to version',
                },
              },
            }"
            >{{ 'Version ' + ver.versionNumber }}</router-link
          >
          {{
            ver.versionNumber === abstract.versionNumber
              ? 'Version ' + ver.versionNumber
              : ''
          }}
          {{ '[' + getDate(ver.firstPublishDate) + ']' }}
          <span v-if="ver.hasEvaluations && ver.hasEvaluations === 'Y'">
            ,
            <a
              v-if="ver.versionNumber === abstract.versionNumber"
              @click.prevent="
                expand($event, 'reviews', true)
                registerMatomoEventForPreprintReviews('Reviews & evaluations')
              "
              >Reviews & evaluations</a
            >
            <router-link
              v-if="ver.versionNumber !== abstract.versionNumber"
              :to="{
                name: 'article',
                params: {
                  source: ver.source,
                  id: ver.id,
                  matomo_event: {
                    category: 'Article',
                    action: 'Preprint version history',
                    name: 'Link to Reviews & evaluations for a version',
                  },
                },
                hash: '#reviews',
              }"
              >Reviews & evaluations</router-link
            >
            available
          </span>
        </li>
      </ul>
    </div>
    <div v-if="showHypothesis" class="pubmed-commons-link">
      <action
        :id="hypothesisAction.id"
        :key="hypothesisAction.id"
        @click="hypothesisAction.onClick"
      >
        <i slot="icon" :class="hypothesisAction.iconClass + ' fa-fw'" /><span
          class="mobile-hide"
          >{{ hypothesisAction.text }}</span
        >
      </action>
      <div>
        PubMed Commons was an experiment by NCBI to support discussion of
        published scientific literature. This article has archived PubMed
        Commons comments.
      </div>
    </div>
    <share-panel
      id="article--social--share"
      label="Share this article"
      :title="abstract.title"
      :url="shareURL"
      @socialMediaButtonClicked="
        ({ socialName }) => {
          registerMatomoEventForSocialShare(socialName)
        }
      "
    />
    <template v-if="showHypothesis">
      <div v-show="!hideHypothesisPanel" id="hypothesis-container">
        <div
          id="hypothesis-close-btn"
          @click.prevent="hideHypothesisPanel = true"
        >
          <i slot="icon" class="fa fa-times"></i>
        </div>
        <div id="hypothesis-panel"></div>
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss">
.article-metadata {
  margin-top: $base-unit * 6.5;

  ul {
    margin: $base-unit 0 ($base-unit * 4);
    margin-left: $base-unit * 3;
    padding-left: $base-unit * 3;
  }

  .metadata--author-list {
    line-height: $base-unit * 6;
    margin-top: $base-unit * 5;
  }

  h2.metadata--dropdown-title {
    margin: ($base-unit * 3) 0 $base-unit;
    .action {
      font-size: $base-unit * 4;
    }
  }

  div.metadata--author-information {
    h3 {
      font-size: $base-unit * 3.75;
      margin: ($base-unit * 2) 0 $base-unit;
    }
    ul.metadata--author-affiliation-list {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      padding: 0;

      li.metadata--author-affiliation-item {
        margin: $base-unit 0;
        padding: $base-unit;
        padding-left: ($base-unit * 1.5);
        list-style: none;

        &.no-focus {
          cursor: auto;
        }
      }
    }
    .metadata--authors-count {
      // display: inline-block;
      // margin-left: $base-unit;
      // min-width: $base-unit * 14;
      // padding: 0 ($base-unit * 1.5);
      // float: right;
      // text-align: center;
      // border-radius: $base-unit / 2;
      // background: $epmc-light-blue;
      color: $epmc-darker-blue;
      font-weight: 600;
      cursor: pointer;

      // font-size: $base-unit * 2.8;
    }
    .metadata--authors-count:hover {
      text-decoration: underline;
    }
    // .focused.metadata--author-affiliation-item,
    // .metadata--author-affiliation-item:hover {
    //   background-color: $epmc-light-blue;

    //   .metadata--authors-count {
    //     background: lighten($epmc-darker-grey, 50%);
    //     color: $epmc-darker-grey;
    //   }
    // }
    .no-focus.metadata--author-affiliation-item:hover {
      background-color: transparent;

      // .metadata--authors-count {
      //   background: $epmc-light-blue;
      //   color: lighten($epmc-darker-grey, 10%);
      // }
    }
    .metadata--author-affiliation-text {
      overflow: hidden;
    }
    ul.metadata--author-orcid-list {
      .icon-orcid,
      .fa-orcid {
        margin-left: $base-unit;
        margin-right: 1px;
      }
      .orcid-link:hover .fa-orcid {
        opacity: 0.8;
      }
    }
  }
  div.metadata--author-showall-link {
    text-align: center;
  }
  span.metadata--author-showall-link a {
    font-weight: 600;
  }
  .metadata--citation {
    line-height: $base-unit * 6;
  }
  .metadata--labels {
    margin: -($base-unit * 2.5) 0 ($base-unit * 3);
    .last-slot > span {
      display: inline-block;
    }
  }
  .metadata--edition,
  .metadata--source-title,
  .metadata--meta-title,
  .metadata--author-affiliation-index {
    font-weight: 600;
  }
  .metadata--language {
    margin-left: $base-unit * 3.5;
    font-weight: 600;
  }
  .metadata--doi,
  .metadata--pmid {
    margin-right: $base-unit * 3;
  }
  .embargo-date {
    color: $epmc-darker-grey;
    margin-top: $base-unit * 4;
  }

  .preprint-ver {
    margin: ($base-unit * 6) 0 ($base-unit * 8);
  }
  .notificationList {
    margin: ($base-unit * 6) 0;
  }
  .pubmed-commons-link,
  .share-panel {
    margin: ($base-unit * 7) 0;
  }
  .metadata--author-affiliation-author-name {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: $base-unit * 6;
    li {
      margin: 0;
      min-width: $base-unit * 14;
      &:first-of-type {
        margin-top: 0;
      }
    }
    .metadata--contributors-heading {
      margin-top: $base-unit * 2;
    }
    h6 {
      margin: 0;
      font-style: normal;
    }
    span {
      margin-bottom: $base-unit * 1;
    }
  }
}
</style>
<style lang="scss">
#hypothesis-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  #hypothesis-panel {
    width: $base-unit * 100;
    height: 100%;
    .h-sidebar-iframe {
      width: $base-unit * 100;
      height: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  #hypothesis-close-btn {
    position: absolute;
    top: 45%;
    margin-left: -$base-unit * 7.5;
    background-color: #ececec;
    padding: $base-unit;
    font-size: $base-unit * 8;
    color: #202020;
    cursor: pointer;
  }
}
</style>
