<script>
import { mapState, mapActions } from 'vuex'
import { Loading } from 'epmc-patterns/components/v2'
import { Tooltip } from 'epmc-patterns/components/v2'
import Timeline from './Timeline'
export default {
  components: { Loading, Tooltip, Timeline },
  data() {
    return {
      loadingEvaluations: false,
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('article/evaluations', ['evaluationList']),
  },
  async created() {
    if (!Object.keys(this.evaluationList.evaluationsGroup).length) {
      this.loadingEvaluations = true
      await this.loadEvaluations()
      this.loadingEvaluations = false
    }
  },
  methods: {
    ...mapActions('article/evaluations', ['loadEvaluations']),
  },
}
</script>
<template>
  <div>
    <p class="italic small">
      Reviews, recommendations &amp; commentary from expert sources.
      <tooltip>
        <i slot="trigger" class="far fa-question-circle" />
        Reviews & evaluations in Europe PMC are sourced from a variety of
        different community groups, peer review platforms and publishers. Peer
        review evaluations are aggregated by Sciety, visit
        <a href="https://sciety.org/groups">Groups on Sciety</a> for more
        information.
      </tooltip>
    </p>
    <loading v-if="loadingEvaluations" />
    <div v-else>
      <timeline :groups="evaluationList.evaluationsGroup" />
    </div>
  </div>
</template>
<style scoped lang="scss">
h3 {
  margin-top: $base-unit * 6;
}
</style>
