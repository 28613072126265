var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline"},_vm._l((_vm.groups),function(group){return _c('div',{key:group.platform,staticClass:"timeline-group"},[_c('div',{staticClass:"timeline-header"},[(group.logoUrl)?_c('div',{staticClass:"timeline-logo"},[_c('img',{attrs:{"src":group.logoUrl}})]):_vm._e(),(group.platform)?_c('h4',{class:['timeline-title', { 'with-logo': group.logoUrl }]},[_vm._v("\n        "+_vm._s(group.platform)+"\n      ")]):_vm._e()]),_c('div',{staticClass:"timeline-content"},_vm._l((group.evaluations),function(evaluation,index){return _c('div',{key:evaluation.id,class:{
          'timeline-item':
            group.evaluations.length > 1 && evaluation.evaluationDate,
          'last-item':
            group.evaluations.length > 1 &&
            evaluation.evaluationDate &&
            (index === group.evaluations.length - 1 ||
              (index < group.evaluations.length - 1 &&
                !group.evaluations[index + 1].evaluationDate)),
          'first-item':
            group.evaluations.length > 1 &&
            index === 0 &&
            evaluation.evaluationDate,
          item: !(group.evaluations.length > 1 && evaluation.evaluationDate),
        }},[(evaluation.evaluationDate)?_c('div',[_vm._v("\n          "+_vm._s(_vm.formatDate(new Date(evaluation.evaluationDate)))+"\n        ")]):_c('div',[_vm._v("No date provided")]),_c('div',{staticClass:"title"},[(evaluation.title)?_c('span',[_vm._v(_vm._s(evaluation.title))]):(evaluation.type)?_c('span',[_vm._v(_vm._s(_vm.formatEvaluationType(evaluation.type)))]):_vm._e()]),(
            evaluation.evaluatorsList &&
            evaluation.evaluatorsList.evaluator &&
            evaluation.evaluatorsList.evaluator.length
          )?_c('div',_vm._l((evaluation.evaluatorsList.evaluator),function(evaluator,eindex){return _c('span',{key:evaluator.name},[(evaluator.name)?_c('span',[_vm._v(_vm._s(evaluator.name))]):_vm._e(),(eindex < evaluation.evaluatorsList.evaluator.length - 1)?_c('span',[_vm._v(",\n            ")]):_vm._e()])}),0):_vm._e(),(evaluation.url)?_c('div',{staticClass:"data-link-item"},[_c('span',{staticClass:"link-container"},[_c('a',{staticClass:"link",attrs:{"href":evaluation.url}},[_c('span',{staticClass:"url"},[_vm._v(_vm._s(evaluation.url))]),_c('i',{staticClass:"fas fa-external-link-alt icon"})])])]):_vm._e()])}),0)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }