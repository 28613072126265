<script>
import DataLink from './DataLink'
import { Action, Loading } from 'epmc-patterns/components/v2'

export default {
  components: {
    DataLink,
    Action,
    Loading,
  },
  props: {
    provider: {
      type: Object,
      default: () => {},
    },
    head: {
      type: String,
      default: '',
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAll: this.expandAll,
      loadingLinks: false,
      links: [],
      search: null,
    }
  },
  computed: {
    element() {
      if (this.head) {
        return this.head
      } else {
        return 'h4'
      }
    },
  },
  watch: {
    search() {
      this.getLinks().then((result) => {
        this.links = result
      })
    },
  },
  async created() {
    this.links = await this.getLinks()
  },
  methods: {
    async getLinks(timeout = 0) {
      const { provider, search, showAll } = this
      const { collectionURL, links } = provider
      return await new Promise((resolve) => {
        this.loadingLinks = true
        setTimeout(() => {
          let result = []
          if (showAll && search) {
            result = links.filter((link) => {
              return link.description
                .toLowerCase()
                .includes(search.toLowerCase())
            })
          } else {
            result = collectionURL || !showAll ? links.slice(0, 5) : links
          }
          resolve(result)
          this.loadingLinks = false
        }, timeout)
      })
    },
    async toggleShowAll() {
      this.showAll = !this.showAll
      this.links = await this.getLinks(this.showAll ? 100 : 0)
    },
  },
}
</script>
<template>
  <div class="data-link-provider">
    <component :is="element" class="data-list-title">
      <template>
        <span v-if="provider.nameLong">{{ provider.nameLong + ' ' }}</span>
        <span v-else-if="provider.name">{{ provider.name + ' ' }}</span>
      </template>
      <span v-if="provider.links.length > 5 && provider.sectionLinkCount">
        (Showing
        {{ links.length }} of {{ provider.sectionLinkCount }})
      </span>
      <span
        v-else-if="provider.sectionLinkCount && provider.sectionLinkCount > 1"
        >{{ '(' + provider.sectionLinkCount + ')' }}
      </span>
      <div>
        <input
          v-if="showAll && provider.sectionLinkCount > 100"
          v-model.trim="search"
          type="text"
          placeholder="Search"
        />
      </div>
    </component>
    <!--<div v-if="provider.description" class="small italic subtitle">
      {{ provider.description }}
    </div>-->
    <div v-if="links.length === 0 && search && showAll">
      There are no results matching your query.
    </div>
    <ul class="data-list">
      <template v-for="(link, index) in links">
        <data-link :key="index" :link="link" :expand-all="expandAll" />
      </template>
      <div
        v-if="
          !provider.collectionURL &&
          provider.links.length > 5 &&
          provider.sectionLinkCount == provider.links.length
        "
        class="collection-url"
      >
        <hr />
        <action
          v-if="!loadingLinks"
          slot="icon"
          class="show-list"
          icon-pos="right"
          @click="toggleShowAll()"
        >
          Show {{ showAll ? 'less' : 'all (' + provider.sectionLinkCount + ')'
          }}<i
            slot="icon"
            :class="['fas', showAll ? 'fa-caret-up' : 'fa-caret-right']"
          />
        </action>
      </div>
    </ul>
    <loading v-if="loadingLinks" />
    <div
      v-if="
        provider.links.length > 5 &&
        provider.sectionLinkCount &&
        provider.collectionURL
      "
      class="collection-url"
    >
      <hr />
      <action :href="provider.collectionURL" class="small" icon-pos="right">
        Go to all ({{ provider.sectionLinkCount }}) records in
        {{ provider.collectionURLIdentifier
        }}<i slot="icon" class="fas fa-external-link-alt" />
      </action>
    </div>
  </div>
</template>
<style lang="scss">
.data-link-provider {
  &:first-child h4 {
    margin-top: $base-unit * 5;
  }
  .subtitle {
    margin: -($base-unit * 2.5) auto ($base-unit * 3);
  }
  .collection-url {
    text-align: center;
    hr {
      margin: ($base-unit * 2) auto;
    }
  }
  ul.data-list {
    list-style-type: none;
    margin: ($base-unit * 2) auto ($base-unit * 3);
    padding: 0;
  }
  a.show-list {
    display: block;
    text-align: center;
  }
  input {
    margin-bottom: ($base-unit * 1);
    margin-top: ($base-unit * 1);
  }
}
</style>
