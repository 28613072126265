<script>
export default {
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      let dateStr = ''
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      dateStr =
        ('0' + date.getDate()).slice(-2) +
        ' ' +
        months[date.getMonth()] +
        ' ' +
        date.getFullYear()
      return dateStr
    },
    formatEvaluationType(type) {
      type = type.replace(/-/g, ' ')
      return this.toSentenceCaseWithPeriod(type)
    },
    toSentenceCaseWithPeriod(value) {
      if (!value) return ''
      return value.replace(/(^|\.\s+)([a-z])/g, (match) => match.toUpperCase())
    },
  },
}
</script>

<template>
  <div class="timeline">
    <div v-for="group in groups" :key="group.platform" class="timeline-group">
      <div class="timeline-header">
        <div v-if="group.logoUrl" class="timeline-logo">
          <img :src="group.logoUrl" />
        </div>
        <h4
          v-if="group.platform"
          :class="['timeline-title', { 'with-logo': group.logoUrl }]"
        >
          {{ group.platform }}
        </h4>
      </div>
      <div class="timeline-content">
        <div
          v-for="(evaluation, index) in group.evaluations"
          :key="evaluation.id"
          :class="{
            'timeline-item':
              group.evaluations.length > 1 && evaluation.evaluationDate,
            'last-item':
              group.evaluations.length > 1 &&
              evaluation.evaluationDate &&
              (index === group.evaluations.length - 1 ||
                (index < group.evaluations.length - 1 &&
                  !group.evaluations[index + 1].evaluationDate)),
            'first-item':
              group.evaluations.length > 1 &&
              index === 0 &&
              evaluation.evaluationDate,
            item: !(group.evaluations.length > 1 && evaluation.evaluationDate),
          }"
        >
          <div v-if="evaluation.evaluationDate">
            {{ formatDate(new Date(evaluation.evaluationDate)) }}
          </div>
          <div v-else>No date provided</div>
          <div class="title">
            <span v-if="evaluation.title">{{ evaluation.title }}</span>
            <span v-else-if="evaluation.type">{{
              formatEvaluationType(evaluation.type)
            }}</span>
          </div>
          <div
            v-if="
              evaluation.evaluatorsList &&
              evaluation.evaluatorsList.evaluator &&
              evaluation.evaluatorsList.evaluator.length
            "
          >
            <span
              v-for="(evaluator, eindex) in evaluation.evaluatorsList.evaluator"
              :key="evaluator.name"
            >
              <span v-if="evaluator.name">{{ evaluator.name }}</span>
              <span
                v-if="eindex < evaluation.evaluatorsList.evaluator.length - 1"
                >,
              </span>
            </span>
          </div>
          <div v-if="evaluation.url" class="data-link-item">
            <span class="link-container">
              <a class="link" :href="evaluation.url">
                <span class="url">{{ evaluation.url }}</span>
                <i class="fas fa-external-link-alt icon" />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title {
  font-weight: bold;
}
.timeline-group {
  border: thin solid lighten($epmc-darker-grey, 50%);
  padding: ($base-unit * 2.5) ($base-unit * 7.5);
  margin: ($base-unit * 4) auto ($base-unit * 8);
}
.timeline-header {
  display: flex;
  align-items: center;
  justify-content: center;
  .timeline-logo {
    flex: 1;
    max-width: $base-unit * 15;
    max-height: $base-unit * 15;
    margin: ($base-unit * 3) ($base-unit * 3) 0 0;
  }
  .timeline-title {
    flex: 1;
    &.with-logo {
      flex: 2;
      margin-left: 0;
    }
  }
}
.timeline-content {
  font-size: $base-unit * 4;
  padding: ($base-unit * 2.5) 0 0 7px;
}
.timeline-item {
  position: relative;
  margin-bottom: $base-unit * 5;
  padding-left: $base-unit * 5;
}
.timeline-item::after {
  content: '';
  position: absolute;
  left: -7px;
  top: $base-unit * 3;
  transform: translateY(-50%);
  width: $base-unit * 3;
  height: $base-unit * 3;
  border: 2px solid #c9c9c9;
  border-radius: 50%;
  background-color: white;
  z-index: 1;
}
.timeline-item::before {
  content: '';
  position: absolute;
  width: 2px;
  height: calc(100% + 20px);
  background-color: #c9c9c9;
  left: 0;
}
.timeline-item.first-item::before {
  top: $base-unit;
}
.timeline-item.last-item::before {
  height: $base-unit * 5;
}
.item {
  position: relative;
  margin-bottom: $base-unit * 5;
  padding-left: $base-unit * 5;
}
.item-date {
  margin-right: $base-unit * 5;
  min-width: $base-unit * 37.5;
}
.item-content {
  flex: 1;
}
.item-type {
  font-weight: bold;
}
.item-url a {
  color: #007bff;
  text-decoration: none;
}
.item-url a:hover {
  text-decoration: underline;
}
.data-link-item {
  margin-top: $base-unit * 2.5;
  overflow: auto;
  border: thin solid lighten($epmc-darker-grey, 64%);
  background-color: #ececec;
  font-size: $base-unit * 3.75;
}
.link-container {
  padding: $base-unit * 2.5;
  display: list-item;
}
.link {
  display: flex;
  align-items: center;
  i {
    margin-left: $base-unit * 2;
  }
  span {
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
