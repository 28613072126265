<script>
import GrantData from './GrantData'
import { Action } from 'epmc-patterns/components/v2'

export default {
  components: {
    GrantData,
    Action,
  },
  props: {
    funderAgency: {
      type: String,
      default: '',
    },
    funderGrants: {
      type: Array,
      default: () => [],
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showGrants: this.expandAll,
      loadGrantData: this.expandAll,
    }
  },
}
</script>
<template>
  <div class="funding-funder small">
    <h3 class="funding-agency-title">
      <action
        v-if="funderGrants.length > 0"
        :id="'article--funder--name-' + funderAgency.replace(/[^a-zA-Z]/g, '-')"
        icon-pos="right"
        @click="
          showGrants = !showGrants
          loadGrantData = true
        "
      >
        {{ funderAgency }}&nbsp;({{ funderGrants.length }})&#xfeff;<i
          slot="icon"
          :class="['fas', showGrants ? 'fa-caret-down' : 'fa-caret-right']"
        />
      </action>
      <span v-else v-html="funderAgency"></span>
    </h3>
    <ul v-show="showGrants" v-if="loadGrantData" class="funding-grant-list">
      <grant-data
        v-for="grant in funderGrants"
        :key="grant.grantId"
        :grant-id="grant.grantId"
        :funder-agency="funderAgency"
        :publications-count="grant.citationCount"
      />
    </ul>
  </div>
</template>
<style scoped lang="scss">
.funding-funder {
  h3,
  h3 .action {
    font-size: $base-unit * 4;
  }
  h3 {
    margin: ($base-unit * 3) auto ($base-unit * 2.5);
  }
  .funding-grant-list {
    list-style-type: none;
    margin: 0 auto ($base-unit * 5);
  }
}
</style>
