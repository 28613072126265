var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageStatus === 'finished')?_c('div',{attrs:{"id":"article-page","itemscope":"","itemtype":`http://schema.org/${
    _vm.abstract && _vm.abstract.bookOrReportDetails
      ? _vm.abstract.bookOrReportDetails.comprisingTitle
        ? 'Chapter'
        : 'Book'
      : 'ScholarlyArticle'
  }`}},[_c('div',{class:[
      'article-page-content',
      'lit-style',
      'content',
      _vm.getFullTextSelectedLanguage(),
    ],attrs:{"id":"epmc-content"}},[_c('div',{staticClass:"grid-row"},[_c('div',{staticClass:"col-3 col-l-4 sticky",attrs:{"id":"article--side-menu-wrapper"}},[_c('div',{attrs:{"id":"article--side-menu"}},[(_vm.screenWidth > 799)?_c('article-menu'):_vm._e()],1)]),_c('div',{staticClass:"col-10 col-l-9 col-s-12 has-sections",attrs:{"id":"article-mid-col"}},[_c('article-metadata'),_c('article-content',{attrs:{"id-url":_vm.id,"source-url":_vm.source}})],1),_c('div',{staticClass:"col-3 sticky"},[(_vm.screenWidth > 1000)?_c('action-bar'):_vm._e()],1)])]),(_vm.showAnnotationsPane)?_c('annotations-pane'):_vm._e(),_c('sticky-footer',{attrs:{"screen-width":_vm.screenWidth}},[_c('article-menu',{ref:"Jump to",attrs:{"slot":"menu1","screen-width":_vm.screenWidth},slot:"menu1"})],1)],1):(_vm.pageStatus === 'error')?_c('div',[_c('h1',[_vm._v("Important Message")]),_c('p',[_vm._v("\n    We are sorry, we are unable to retrieve the citation details for this\n    record.\n  ")])]):_c('loading')
}
var staticRenderFns = []

export { render, staticRenderFns }