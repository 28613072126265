<script>
import { mapState, mapActions } from 'vuex'

import Sticky from '@/helpers/sticky'
import WindowResize from '@/helpers/window-resize'

import { Loading } from 'epmc-patterns/components/v2'

import ActionBar from '@/templates/ActionBar'
import AnnotationsPane from '@/templates/article/AnnotationsPane'
import ArticleContent from '@/templates/article/ArticleContent'
import ArticleMenu from '@/templates/article/ArticleMenu'
import ArticleMetadata from '@/templates/article/ArticleMetadata'
import StickyFooter from '@/templates/StickyFooter'

export default {
  components: {
    ActionBar,
    AnnotationsPane,
    ArticleContent,
    ArticleMenu,
    ArticleMetadata,
    Loading,
    StickyFooter,
  },
  mixins: [Sticky, WindowResize],
  props: {
    source: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    bookid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pageStatus: 'loading',
    }
  },
  metaInfo() {
    const { abstract } = this
    return {
      title: abstract ? abstract.title.replace(/(<([^>]+)>)/gi, '') : '',
      titleTemplate: '%s - Abstract - Europe PMC',
      meta: [
        {
          name: 'description',
          content: abstract ? abstract.title : '',
        },
      ],
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('article/annotations', ['showAnnotationsPane']),
    ...mapState('article/fulltext', ['fulltextHtml']),
  },
  watch: {
    $route: 'getArticle',
  },
  created() {
    this.getArticle()
  },
  beforeRouteLeave(to, from, next) {
    this.resetArticleState()
    next()
  },
  methods: {
    ...mapActions('article', ['loadArticle', 'resetArticleState']),
    getFullTextSelectedLanguage() {
      const { fulltextHtml } = this
      const html = document.createElement('div')
      html.innerHTML = fulltextHtml
      const selectedLanguage = html.querySelector('.lang-sw.selected')
      return selectedLanguage ? `slang-${selectedLanguage.lang}` : ''
    },
    getArticle() {
      this.pageStatus = 'loading'
      this.loadArticle({
        id: this.id,
        source: this.source,
        botRequest: window.location.search.indexOf('client=bot') !== -1,
      })
        .then(() => {
          this.pageStatus = 'finished'
        })
        .catch(() => {
          this.pageStatus = 'error'
        })
    },
  },
}
</script>
<template>
  <div
    v-if="pageStatus === 'finished'"
    id="article-page"
    itemscope
    :itemtype="`http://schema.org/${
      abstract && abstract.bookOrReportDetails
        ? abstract.bookOrReportDetails.comprisingTitle
          ? 'Chapter'
          : 'Book'
        : 'ScholarlyArticle'
    }`"
  >
    <div
      id="epmc-content"
      :class="[
        'article-page-content',
        'lit-style',
        'content',
        getFullTextSelectedLanguage(),
      ]"
    >
      <div class="grid-row">
        <div id="article--side-menu-wrapper" class="col-3 col-l-4 sticky">
          <div id="article--side-menu">
            <article-menu v-if="screenWidth > 799" />
          </div>
        </div>
        <div id="article-mid-col" class="col-10 col-l-9 col-s-12 has-sections">
          <article-metadata />
          <article-content :id-url="id" :source-url="source" />
        </div>
        <div class="col-3 sticky">
          <action-bar v-if="screenWidth > 1000" />
        </div>
      </div>
    </div>
    <annotations-pane v-if="showAnnotationsPane" />
    <sticky-footer :screen-width="screenWidth">
      <article-menu slot="menu1" ref="Jump to" :screen-width="screenWidth" />
    </sticky-footer>
  </div>
  <div v-else-if="pageStatus === 'error'">
    <h1>Important Message</h1>
    <p>
      We are sorry, we are unable to retrieve the citation details for this
      record.
    </p>
  </div>
  <loading v-else />
</template>
<style lang="scss">
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  bottom: 0;
  max-height: 100vh;
}

#article-mid-col {
  margin-bottom: $base-unit * 5;
}

.article-page-content .has-sections .notification:first-child {
  margin: ($base-unit * 7) auto;
}

#article--side-menu-wrapper {
  overflow: visible !important;
  #article--side-menu {
    overflow: auto;
    max-height: 100vh;
    @media screen and (max-width: $breakpoint-extra-small) {
      height: auto;
    }
  }
}
</style>
