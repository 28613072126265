<script>
import { mapState } from 'vuex'
import Grant from '../Grant'

export default {
  components: {
    Grant,
  },
  data() {
    return {
      uniqueFundersList: [],
      eachFunderGrantIds: [],
      countsLoaded: false,
    }
  },
  computed: mapState('article/abstract', ['abstract']),
  created() {
    this.uniqueFundersList = [
      ...new Set(this.abstract.grantsList.grant.map((a) => a.agency)),
    ]
    this.uniqueFundersList.map((agencyName) => {
      const agency = {}
      agency.name = agencyName
      agency.grants = []

      const agencyArr = this.abstract.grantsList.grant.filter(function (a) {
        return a.agency === agencyName
      })
      agencyArr.forEach(function (a) {
        if (a.grantId) {
          const grant = {}
          grant.grantId = a.grantId
          agency.grants.push(grant)
        }
      })
      this.eachFunderGrantIds.push(agency)
    })
    this.eachFunderGrantIds.sort(function (a, b) {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    })
  },
}
</script>
<template>
  <div>
    <p>Funders who supported this work.</p>
    <grant
      v-for="funder in eachFunderGrantIds"
      :key="funder.name"
      :funder-agency="funder.name"
      :funder-grants="funder.grants"
      :expand-all="abstract.botRequest"
    />
  </div>
</template>
