var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"funding-grant"},[(_vm.grantTitle)?[_c('h4',{staticClass:"funding-grant-title small"},[_c('router-link',{attrs:{"href":'/grantfinder/grantdetails?query=' + _vm.grantTitleQuery,"to":_vm.grantTitleLink}},[_vm._v(_vm._s(_vm.grantTitle))])],1),(_vm.familyName)?_c('p',[_vm._v("\n      "+_vm._s(_vm.personTitle)+" "+_vm._s(_vm.givenName)+" "+_vm._s(_vm.familyName)+_vm._s(_vm.institution ? ', ' + _vm.institution : '')+"\n    ")]):_vm._e()]:_vm._e(),_c('p',[_vm._v("Grant ID: "+_vm._s(_vm.grantId))]),_c('p',{staticClass:"funding-grant-link"},[(_vm.countsLoaded)?[(_vm.publicationsCount > 0)?_c('router-link',{attrs:{"to":{
          name: 'search',
          query: {
            query:
              'GRANT_AGENCY_ID:"' +
              _vm.grantId +
              '_agency_' +
              _vm.funderAgency +
              '"',
          },
        }}},[_vm._v("\n        "+_vm._s(_vm.publicationsCount)+" publication"),(_vm.publicationsCount > 1)?_c('span',[_vm._v("s")]):_vm._e()]):[_vm._v("0 publications")]]:[_c('img',{attrs:{"src":require("@/assets/menu-item-loading-icon.gif"),"alt":"Loading"}}),_vm._v("\n      publications\n    ")]],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }