var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-link-provider"},[_c(_vm.element,{tag:"component",staticClass:"data-list-title"},[[(_vm.provider.nameLong)?_c('span',[_vm._v(_vm._s(_vm.provider.nameLong + ' '))]):(_vm.provider.name)?_c('span',[_vm._v(_vm._s(_vm.provider.name + ' '))]):_vm._e()],(_vm.provider.links.length > 5 && _vm.provider.sectionLinkCount)?_c('span',[_vm._v("\n      (Showing\n      "+_vm._s(_vm.links.length)+" of "+_vm._s(_vm.provider.sectionLinkCount)+")\n    ")]):(_vm.provider.sectionLinkCount && _vm.provider.sectionLinkCount > 1)?_c('span',[_vm._v(_vm._s('(' + _vm.provider.sectionLinkCount + ')')+"\n    ")]):_vm._e(),_c('div',[(_vm.showAll && _vm.provider.sectionLinkCount > 100)?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.search),expression:"search",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}):_vm._e()])],2),(_vm.links.length === 0 && _vm.search && _vm.showAll)?_c('div',[_vm._v("\n    There are no results matching your query.\n  ")]):_vm._e(),_c('ul',{staticClass:"data-list"},[_vm._l((_vm.links),function(link,index){return [_c('data-link',{key:index,attrs:{"link":link,"expand-all":_vm.expandAll}})]}),(
        !_vm.provider.collectionURL &&
        _vm.provider.links.length > 5 &&
        _vm.provider.sectionLinkCount == _vm.provider.links.length
      )?_c('div',{staticClass:"collection-url"},[_c('hr'),(!_vm.loadingLinks)?_c('action',{staticClass:"show-list",attrs:{"slot":"icon","icon-pos":"right"},on:{"click":function($event){return _vm.toggleShowAll()}},slot:"icon"},[_vm._v("\n        Show "+_vm._s(_vm.showAll ? 'less' : 'all (' + _vm.provider.sectionLinkCount + ')')),_c('i',{class:['fas', _vm.showAll ? 'fa-caret-up' : 'fa-caret-right'],attrs:{"slot":"icon"},slot:"icon"})]):_vm._e()],1):_vm._e()],2),(_vm.loadingLinks)?_c('loading'):_vm._e(),(
      _vm.provider.links.length > 5 &&
      _vm.provider.sectionLinkCount &&
      _vm.provider.collectionURL
    )?_c('div',{staticClass:"collection-url"},[_c('hr'),_c('action',{staticClass:"small",attrs:{"href":_vm.provider.collectionURL,"icon-pos":"right"}},[_vm._v("\n      Go to all ("+_vm._s(_vm.provider.sectionLinkCount)+") records in\n      "+_vm._s(_vm.provider.collectionURLIdentifier)),_c('i',{staticClass:"fas fa-external-link-alt",attrs:{"slot":"icon"},slot:"icon"})])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }