<script>
import { mapState } from 'vuex'
import { containEmail, stripEmail } from 'epmc-patterns/helpers'
import { registerMatomoEvent } from '@/helpers/matomo'
import { Action, Modal } from 'epmc-patterns/components/v2'

export default {
  components: {
    Action,
    Modal,
  },
  props: {
    author: {
      type: Object,
      default: () => ({}),
    },
    authorName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showAllAffiliations: false,
    }
  },
  computed: {
    ...mapState('search', ['searchParams']),
    getAuthorAffWithoutEmail() {
      const { affiliation } = this.author
      return (
        affiliation &&
        (affiliation.match(/^[a-z]\s/)
          ? stripEmail(affiliation.substring(2))
          : stripEmail(affiliation))
      )
    },
    getAllEmailsFromAuthorAff() {
      const { affiliation } = this.author
      return affiliation && containEmail(affiliation, true)
    },
    getAuthorSearchLink() {
      return {
        name: 'search',
        query: {
          query: 'AUTH:"' + this.authorName + '"',
        },
      }
    },
    getFilterSearchLink() {
      return {
        name: 'search',
        query: {
          query:
            this.searchParams.query + ' AND AUTH:"' + this.authorName + '"',
        },
      }
    },
  },
  methods: {
    sendEmail(email) {
      window.location = `mailto:${email}`
      this.registerMatomoEventForAuthorPopup('Email')
    },
    registerMatomoEventForAuthorPopup(eventName) {
      registerMatomoEvent('Article', 'Author name - popup', eventName)
    },
    closeMetadataAuthorPopup() {
      this.$emit('close')
    },
    removeEmailFromAffiliation(affiliation) {
      return stripEmail(affiliation)
    },
  },
}
</script>
<template>
  <modal
    class="large"
    :close-when-clicking-outside="true"
    @close="closeMetadataAuthorPopup"
  >
    <template slot="title">
      {{ authorName }}
    </template>
    <div class="author-popup-content">
      <ul class="author-popup-links">
        <template v-if="getAllEmailsFromAuthorAff">
          <li v-for="email in getAllEmailsFromAuthorAff" :key="email">
            <action data-email @click="sendEmail(email)">
              <i slot="icon" class="fas fa-envelope" /><span class="email">{{
                email.split().reverse().join('')
              }}</span>
            </action>
          </li>
        </template>

        <template v-if="author.authorId">
          <li class="author-popup-link-item">
            <action
              :to="{
                name: 'authorProfile',
                params: { orcid: author.authorId.value },
              }"
              itemprop="mainEntityOfPage"
              @click="registerMatomoEventForAuthorPopup('Author Profile')"
            >
              <i slot="icon" class="fas fa-user" />Author profile
            </action>
          </li>

          <li class="author-popup-link-item">
            <action
              :to="{
                name: 'search',
                query: { query: 'AUTHORID:' + author.authorId.value },
              }"
              @click="
                registerMatomoEventForAuthorPopup('Search articles by ORCID')
              "
            >
              <i slot="icon" class="fas fa-search" />Search for articles linked
              to Author's ORCID
            </action>
          </li>

          <li class="author-popup-link-item">
            <action
              :href="'//orcid.org/' + author.authorId.value"
              @click="registerMatomoEventForAuthorPopup('External ORCID')"
            >
              <i slot="icon" class="fab fa-orcid" />{{ author.authorId.value
              }}<i class="fas fa-external-link-alt" />
            </action>
          </li>
        </template>
        <template v-else>
          <li class="author-popup-link-item">
            <action :to="getAuthorSearchLink">
              <i slot="icon" class="fas fa-search" />
              <span>Search articles by '{{ authorName }}'</span>
            </action>
          </li>
        </template>
        <template
          v-if="
            author.authorAffiliationDetailsList &&
            author.authorAffiliationDetailsList.authorAffiliation &&
            author.authorAffiliationDetailsList.authorAffiliation.length > 0
          "
        >
          <h6 style="font-style: normal">
            Affiliation{{
              author.authorAffiliationDetailsList.authorAffiliation.length > 1
                ? 's'
                : ''
            }}
          </h6>
          <ul class="author-affiliations">
            <li
              v-for="(aff, index) in showAllAffiliations
                ? author.authorAffiliationDetailsList.authorAffiliation
                : author.authorAffiliationDetailsList.authorAffiliation.slice(
                    0,
                    5
                  )"
              :key="`${author.affiliation}-${index}`"
            >
              <b>{{ index + 1 }}.</b>
              {{ removeEmailFromAffiliation(aff.affiliation) }}
            </li>
          </ul>
          <template
            v-if="
              author.authorAffiliationDetailsList.authorAffiliation.length > 5
            "
          >
            <a
              style="font-weight: bold"
              @click.prevent="showAllAffiliations = !showAllAffiliations"
            >
              <span v-if="showAllAffiliations"> Show less </span>
              <span v-else>
                Show all ({{
                  author.authorAffiliationDetailsList.authorAffiliation.length
                }})
              </span>
              <i
                slot="icon"
                :class="[
                  'fas',
                  showAllAffiliations ? 'fa-caret-down' : 'fa-caret-right',
                ]"
              />
            </a>
          </template>
        </template>
      </ul>
    </div>
  </modal>
</template>
<style scoped lang="scss">
.author-popup-links {
  margin: 0;
  padding: 0;
  list-style-type: none;
  .author-popup-link-item {
    margin: ($base-unit * 2) auto;
    &:first-of-type {
      margin-top: 0;
    }
    a {
      &:hover i.fa-orcid {
        opacity: 0.8;
      }
      &[data-email] {
        white-space: nowrap;
      }
      i.fa-external-link-alt {
        margin-left: $base-unit;
      }
    }
  }
  .author-affiliations {
    padding-left: 0;
    list-style: none;
  }
}
</style>
