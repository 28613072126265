<script>
import Vue from 'vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import VueRecaptcha from 'vue-recaptcha'
import { sendFeedback, fetchAnnotations } from '@/api'
import { scrollTo } from 'epmc-patterns/helpers'
import { addScript } from '@/helpers/load-script-and-style'
import Annotations from '@/helpers/article/annotation'
import { registerMatomoEvent } from '@/helpers/matomo'
import {
  Action,
  Loading,
  Modal,
  NotificationList,
  Tooltip,
} from 'epmc-patterns/components/v2'
import ViewStructure from './ViewStructure'

const labelShowLimit = 10

export default {
  filters: {
    allLabelsSelected(cat) {
      return !cat.labels.find((label) => !label.selected)
    },
  },
  components: {
    Action,
    Loading,
    NotificationList,
    Tooltip,
    ViewStructure,
  },
  data() {
    return {
      // highlightElmId: { cat, annotation }
      highlightElms: {},

      labelShowLimit,
      labelShow: [],
      lastHighlightIndex: -1,
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('article/annotations', [
      'annotations',
      'annotationsLoaded',
      'annotationsCount',
      'canHighlight',
      'activeTab',
    ]),
    hasAnnotations() {
      const { annotations } = this
      return (
        annotations && annotations.categories.find((cat) => cat.labels.length)
      )
    },
    hasMetagenomics() {
      const { annotations, annotationsLoaded } = this

      return (
        annotationsLoaded &&
        annotations &&
        annotations.hasMetagenomicsProviderData === true
      )
    },
    getNotificationList() {
      // ['ppr', 'restrictions', 'no', 'metagenomics']
      const {
        abstract,
        annotationsLoaded,
        canHighlight,
        hasAnnotations,
        hasMetagenomics,
      } = this
      const list = []
      if (!canHighlight) {
        if (abstract.source === 'PPR') {
          list.push('ppr')
        } else {
          list.push('restrictions')
        }
      }
      if (annotationsLoaded) {
        if (!hasAnnotations) {
          list.push('no')
        } else if (hasMetagenomics) {
          list.push('metagenomics')
        }
      }
      return list
    },
    annotationAPI() {
      const { abstract } = this
      return (
        '//www.ebi.ac.uk/europepmc/annotations_api/annotationsByArticleIds?articleIds=' +
        abstract.source +
        ':' +
        abstract.id +
        '&format=JSON'
      )
    },
    nonEmptyCategories() {
      const nonEmptyCategories = this.annotations.categories.filter(
        (cat) => cat.labels.length
      )

      // 2) All the data except where the providers array have a provider with name "Biostudies"
      nonEmptyCategories.map((item) => {
        let biostudiesCount = 0
        let nonBiostudiesCount = 0
        return (item.labels = item.labels.map((label) => {
          // Filter annotations to exclude those with 'Biostudies' provider
          label.annotations = label.annotations.filter(
            (annotation) =>
              !annotation.providers.some(
                (provider) => provider.provider === 'Biostudies'
              )
          )

          // Filter providers at the label level
          const labelWithBiostudies = label.providers.find(
            (provider) => provider.provider === 'Biostudies'
          )
          const labelWithoutBiostudies = label.providers.find(
            (provider) => provider.provider !== 'Biostudies'
          )

          if (labelWithBiostudies && labelWithoutBiostudies) {
            label.haveMultipleProviders = true
          } else if (labelWithBiostudies) {
            label.isBiostudies = true
            label.haveMultipleProviders = false
            biostudiesCount = biostudiesCount + 1
          } else {
            label.isBiostudies = false
            label.haveMultipleProviders = false
            nonBiostudiesCount = nonBiostudiesCount + 1
          }
          if (item.labels.length === biostudiesCount) {
            item.containsOnlyBiostudiesData = true
            label.haveMultipleProviders = false
          } else item.containsOnlyBiostudiesData = false

          if (item.labels.length === nonBiostudiesCount) {
            item.containsOnlyNonBiostudiesData = true
            label.haveMultipleProviders = false
          } else item.containsOnlyNonBiostudiesData = false

          return label
        }))
      })
      return nonEmptyCategories
    },
  },
  async mounted() {
    await this.showTab(this.activeTab)
    await this.$nextTick()

    await this.loadAnnotations()
    this.labelShow = this.nonEmptyCategories.map(() => labelShowLimit)

    await addScript({
      src: 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit',
      async: '',
      defer: '',
    })
  },
  beforeDestroy() {
    // remove all listeners
    this.handleAnnotationPopupListener('removeEventListener')
    this.clearAnnotations()
  },
  methods: {
    ...mapMutations('article/annotations', ['changeShowAnnotationsPane']),
    ...mapActions('article/annotations', [
      'loadAnnotations',
      'clearAnnotations',
      'selectCategory',
      'selectLabel',
      'goToAnnotation',
    ]),
    async checkCategory(cat) {
      // remove before select because the latter will change content
      this.handleAnnotationPopupListener('removeEventListener')
      await this.selectCategory(cat)
      await this.$nextTick()
      cat.labels.forEach((label) => {
        this.handleAnnotationPopup(cat, label)
      })
      this.handleAnnotationPopupListener('addEventListener')
      // if all labels in the category selected, record the matomo event
      if (!cat.labels.find((label) => !label.selected)) {
        this.registerMatomoEventForAnnotations(
          'Annotations - Highlight all',
          cat.title
        )
      }
    },
    async checkLabel(cat, label) {
      this.handleAnnotationPopupListener('removeEventListener')
      await this.selectLabel(label)
      await this.$nextTick()
      this.handleAnnotationPopup(cat, label)
      this.handleAnnotationPopupListener('addEventListener')
      // if the label is selected, record the matomo event
      if (label.selected) {
        this.registerMatomoEventForAnnotations(
          'Annotations - Highlight term',
          cat.title + '-' + label.title
        )
      }
    },
    showLabel(cat, index) {
      const { labelShow } = this
      this.$set(
        labelShow,
        index,
        labelShow[index] === labelShowLimit ? cat.labels.length : labelShowLimit
      )
    },
    handleAnnotationPopup(cat, label) {
      label.annotations.forEach((annotation) => {
        const { highlightIndex } = annotation
        const highlightElmId = Annotations.highlightIdPattern + highlightIndex

        if (label.selected) {
          this.$set(this.highlightElms, highlightElmId, { cat, annotation })
        } else {
          this.$delete(this.highlightElms, highlightElmId)
        }
      })
    },
    handleAnnotationPopupListener(listener) {
      const { addAnnotationPopup } = this

      for (const highlightElmId in this.highlightElms) {
        const highlightElm = document.getElementById(highlightElmId)
        if (highlightElm) {
          const e = this.highlightElms[highlightElmId]

          if (listener === 'addEventListener') {
            const handler = addAnnotationPopup(
              highlightElmId,
              e.cat,
              e.annotation
            )
            e.handler = handler
            highlightElm[listener]('mouseenter', handler)
          } else {
            highlightElm[listener]('mouseenter', e.handler)
            delete e.handler
          }
        }
      }
    },
    getTagLink(tag) {
      return Annotations.getTagLink(tag)
    },
    getTagLinkLabel(tag) {
      return Annotations.getTagLinkLabel(tag)
    },
    getBioStructureInfoFromTag(tag) {
      return Annotations.getBioStructureInfoFromTag(tag)
    },
    addAnnotationPopup(highlightElmId, cat, annotation) {
      return () => {
        const { addAnnotationPopup } = this

        const currentClass = document
          .getElementById(highlightElmId)
          .classList.contains('current')
          ? 'current'
          : ''

        const annotationPopupVm = new Vue({
          el: '#' + highlightElmId,
          components: { Action, Modal, Tooltip, ViewStructure, VueRecaptcha },
          data() {
            return {
              annotation,

              showEndorseModal: false,

              showReportModal: false,
              problem: null,
              message: '',
              submitted: false,

              showShareLinkModal: false,
              shareLinkCopied: false,

              popupShown: false,
            }
          },
          methods: {
            removeAnnotationPopup() {
              annotationPopupVm.$destroy()
              const highlightElm = document.getElementById(highlightElmId)
              highlightElm.innerHTML = document.getElementById(
                highlightElmId + '-inner'
              ).innerHTML
              highlightElm.classList = `scilite-annotation ${currentClass}`
              highlightElm.addEventListener(
                'mouseenter',
                addAnnotationPopup(highlightElmId, cat, annotation)
              )
            },
            endorse(code) {
              const email = `
                TIMESTAMP\t${new Date().toLocaleString()}
                ARTICLE\t${window.location.href.replace(
                  window.location.hash,
                  ''
                )}
                JUDGE\tgood
                SEMANTIC TYPE\t${cat.title}
                TEXT ANNOTATION\t${annotation.exact}
                PREFIX\t${annotation.prefix}
                POSTFIX\t${annotation.postfix}
                MESSAGE
                URI\t${annotation.id}
                BODY
              `
              sendFeedback('Annotation feedback POSITIVE', email, code)
              this.showEndorseModal = true
              this.registerMatomoEventForAnnotations(
                'Annotations - Popup in text',
                'Endorse'
              )
            },
            report(code) {
              const email = `
                TIMESTAMP\t${new Date().toLocaleString()}
                ARTICLE\t${window.location.href.replace(
                  window.location.hash,
                  ''
                )}
                JUDGE\t${this.problem}
                SEMANTIC TYPE\t${cat.title}
                TEXT ANNOTATION\t${annotation.exact}
                PREFIX\t${annotation.prefix}
                POSTFIX\t${annotation.postfix}
                MESSAGE\t${this.message}
                URI\t${annotation.id}
                BODY\t${annotation.providers[0].tags[0].uri}
              `
              sendFeedback('Annotation feedback NEGATIVE', email, code)
              this.submitted = true
              this.registerMatomoEventForAnnotations(
                'Annotations - Popup in text',
                'Feedback sent'
              )
            },
            share() {
              const copy = this.$refs.shareLink
              copy.select()
              copy.setSelectionRange(0, 99999)
              document.execCommand('copy')
              this.shareLinkCopied = true
            },
            registerMatomoEventForAnnotations(eventAction, eventName) {
              registerMatomoEvent('Article', eventAction, eventName)
            },
            getTagLink(tag) {
              return Annotations.getTagLink(tag)
            },
            getTagLinkLabel(tag) {
              return Annotations.getTagLinkLabel(tag)
            },
            showPopup() {
              this.popupShown = true
              this.registerMatomoEventForAnnotations(
                'Annotations - Popup in text',
                cat.title
              )
            },
            getBioStructureInfoFromTag(tag) {
              const info = Annotations.getBioStructureInfoFromTag(tag)
              return info
            },
          },
          template: `
              <modal v-if="showEndorseModal" @close="showEndorseModal = false">
                <template slot="title">Thanks for your feedback</template>
                Thank you for your help to improve the quality and relevance of annotations.
              </modal>

              <modal v-else-if="showReportModal" class="show-report-modal" @close="showReportModal = false">
                <template slot="title">Report a problem</template>

                <template v-if="submitted">
                  Thank you for your help to improve the quality and relevance of annotations.
                  Our support staff will review your comments soon.
                </template>
                <template v-else>
                  <h4>What is wrong with this instance of "${
                    annotation.exact
                  }"?</h4>
                  <label
                  ><input v-model="problem" type="radio" value="incorrect" />It is
                  incorrect</label
                  ><br/>
                  <label
                  ><input v-model="problem" type="radio" value="generic" />It is
                  too generic</label
                  ><br/>
                  <label
                  ><input v-model="problem" type="radio" value="other" />Other</label
                  >
                  <h4>
                    To help us further, please briefly explain the problem.
                    (<em>Optional</em>)
                  </h4>
                  <textarea
                    v-model="message"
                    placeholder="Enter text"
                    class="annotation-tooltip-input"
                  ></textarea>
                  <div>
                    <button :disabled="!problem" class="annotation-tooltip-btn" @click="$refs.reportRecaptcha.execute()">
                      Submit
                    </button>
                  </div>
                  <vue-recaptcha
                    ref="reportRecaptcha"
                    sitekey="6Ldb8sMUAAAAACGnl-_JQOpBmUGFkavmFduMT6_D"
                    size="invisible"
                    badge="inline"
                    @verify="report"
                    @expire="$refs.reportRecaptcha.reset()"
                  ></vue-recaptcha>
                  <hr class="reCAPTCHA-claim-separator" />
                  <div class="extra-small">
                    This site is protected by reCAPTCHA and the Google
                    <a href="//policies.google.com/privacy">Privacy Policy</a> and
                    <a href="//policies.google.com/terms">Terms of Service</a>
                    apply.
                  </div>
                </template>
              </modal>

              <modal v-else-if="showShareLinkModal" @close="showShareLinkModal = false">
                <template slot="title">Share</template>
                <div class="semi-bold">
                  Share link
                  <span v-if="shareLinkCopied" class="share-link-copied">
                    <i class="fas fa-check"></i> Copied
                  </span>
                </div>
                <input class="annotation-tooltip-input" type="text" readonly ref="shareLink" @click="share" value="${
                  annotation.id
                }"></div>
              </modal>

              <tooltip v-else id="${highlightElmId}" title="${
            cat.title
          }" placement="bottom" :delay-on-mouse-out="300" max-width="none" @show="showPopup()" @close="removeAnnotationPopup()">
                <span slot="trigger" id="${highlightElmId}-inner" class="scilite-annotation">${
            document.getElementById(highlightElmId).innerHTML
          }</span>
                <div
                  v-for="provider in annotation.providers"
                  :key="provider.provider"
                >

                  <div class="small italic">
                    Provided by {{ provider.provider }}
                  </div>

                  <h5 class="annotation-tooltip-label-title">${
                    annotation.exact
                  }</h5>

                  <div v-for="tag in provider.tags" :key="tag.name">
                    <a class="semi-bold" :href="getTagLink(tag)" target="_blank" @click="registerMatomoEventForAnnotations('Annotations - Popup in text', 'Database link')"
                      >{{ getTagLinkLabel(tag) }}
                      <i class="fas fa-external-link-alt"></i
                    ></a>
                    <view-structure
                      v-if="popupShown && getBioStructureInfoFromTag(tag)"
                      :link-scheme="getBioStructureInfoFromTag(tag).linkScheme"
                      :link-id="getBioStructureInfoFromTag(tag).linkId"
                      :collapsible="false"
                      :expand-all="true"
                      :hideCanvasControls="['expand', 'selection', 'animation']"
                    />
                  </div>
                </div>

                <hr class="annotation-tooltip-footer" />
                <action @click="$refs.endorseRecaptcha.execute()">
                  <i slot="icon" class="far fa-thumbs-up" />Endorse
                </action>
                <span class="annotation-popup-separator"></span>
                <action @click="
                  showReportModal = true
                  registerMatomoEventForAnnotations('Annotations - Popup in text', 'Report')
                ">
                  <i slot="icon" class="far fa-thumbs-down" />Report
                </action>
                <span class="annotation-popup-separator"></span>
                <action @click="showShareLinkModal = true">
                  <i slot="icon" class="fas fa-share"></i>Share
                </action>
                <vue-recaptcha
                  sitekey="6Ldb8sMUAAAAACGnl-_JQOpBmUGFkavmFduMT6_D"
                  ref="endorseRecaptcha"
                  @verify="endorse"
                  @expire="$refs.endorseRecaptcha.reset()"
                  size="invisible"
                  badge="inline"
                ></vue-recaptcha>
              </tooltip>
            `,
        })
      }
    },
    async navAnnotation(label, prev) {
      await this.goToAnnotation({ label, prev })

      await this.$nextTick()

      const { nextAnnotationIndex } = label
      const { highlightIndex } = label.annotations[nextAnnotationIndex]
      const highlightElmId = Annotations.highlightIdPattern + highlightIndex
      const highlightElm = document.getElementById(highlightElmId)
      const highlightElmSelector = '#' + highlightElmId
      scrollTo(highlightElmSelector, highlightElmSelector, 64)
      highlightElm.classList.add('current')

      if (
        this.lastHighlightIndex !== -1 &&
        this.lastHighlightIndex !== highlightIndex
      ) {
        const lastHighlightElmId =
          Annotations.highlightIdPattern + this.lastHighlightIndex
        const lastHighlightElm = document.getElementById(lastHighlightElmId)
        lastHighlightElm.classList.remove('current')
      }
      this.lastHighlightIndex = highlightIndex
    },
    registerMatomoEventForAnnotations(eventAction, eventName) {
      registerMatomoEvent('Article', eventAction, eventName)
    },
    showTooltip(category, label) {
      label.hovered = true
      this.registerMatomoEventForAnnotations(
        'Annotations - Popup in panel',
        category.title
      )
    },
    showTab(tabId) {
      if (this.annotations) {
        this.labelShow = this.nonEmptyCategories.map(() => labelShowLimit)
      }
      const contents = document.querySelectorAll('.tab-content')
      const buttons = document.querySelectorAll('.tab-button')

      contents.forEach((content) => content.classList.remove('active'))
      buttons.forEach((button) => button.classList.remove('active'))

      document.getElementById(tabId).classList.add('active')
      document.getElementById(`${tabId}_button`).classList.add('active')
    },
    exportAnnotations() {
      const { abstract } = this
      const { source, id, hasFulltext } = abstract
      const data = fetchAnnotations(source, id, !hasFulltext)
      data.then((result) => {
        if (
          result &&
          result.length > 0 &&
          result[0].annotations &&
          result[0].annotations.length > 0
        ) {
          let fulltextContent = ``
          let supplementaryContent = ''
          const groupedAnnotations = {}
          const groupedSupplementary = {}

          result[0].annotations.forEach((annotation) => {
            if (annotation.type === '') return

            const uri =
              annotation.tags && annotation.tags.length > 0
                ? annotation.tags[0].uri
                : ''
            const formattedData = `${annotation.type} - ${annotation.exact} - ${uri}`
            if (annotation.provider === 'Biostudies') {
              const fileName = annotation.fileName ? annotation.fileName : ''
              const type = annotation.type
              if (!groupedSupplementary[type]) {
                groupedSupplementary[type] = {}
              }
              if (!groupedSupplementary[type][annotation.exact]) {
                groupedSupplementary[type][annotation.exact] = {
                  data: `${type} - ${annotation.exact} - ${uri}`,
                  files: new Set(),
                }
              }
              groupedSupplementary[type][annotation.exact].files.add(fileName)
            } else {
              if (!groupedAnnotations[annotation.type]) {
                groupedAnnotations[annotation.type] = ''
              }
              groupedAnnotations[annotation.type] += `${formattedData}\n`
            }
          })

          // Sort and append Fulltext annotations
          const sortedFulltextTypes = Object.keys(groupedAnnotations).sort()
          sortedFulltextTypes.forEach((type) => {
            if (groupedAnnotations[type]) {
              fulltextContent += `${groupedAnnotations[type]}\n`
            }
          })

          // Process and sort Supplementary Files annotations
          const sortedSupplementaryTypes =
            Object.keys(groupedSupplementary).sort()
          sortedSupplementaryTypes.forEach((type, index) => {
            const exactAnnotations = groupedSupplementary[type]
            const sortedExactAnnotations = Object.keys(exactAnnotations).sort()
            sortedExactAnnotations.forEach((exact) => {
              const { data, files } = exactAnnotations[exact]
              const fileNames = Array.from(files).join(', ')
              supplementaryContent += `${data} - ${fileNames}\n`
            })
            // Add an extra line between different types, except after the last one
            if (index < sortedSupplementaryTypes.length - 1) {
              supplementaryContent += '\n'
            }
          })

          if (supplementaryContent) {
            supplementaryContent = `Supplementary Files\n${supplementaryContent}`
          }

          if (fulltextContent) {
            fulltextContent = `${
              abstract.hasFulltext ? 'Full text' : 'Abstract'
            }\n${fulltextContent}`
          }

          const blob = new Blob([fulltextContent + supplementaryContent], {
            type: 'text/plain',
          })
          const a = document.createElement('a')
          a.download = 'annotations.txt'
          a.href = URL.createObjectURL(blob)
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    constructBiostudiesFileUrl(fileName) {
      const { abstract } = this
      const { pmcid } = abstract
      const biostudiesFilePath = 'https://www.ebi.ac.uk/biostudies/files'
      return `${biostudiesFilePath}/S-E${pmcid}/${fileName}`
    },
  },
}
</script>
<template>
  <div id="annotations-pane-wrapper">
    <div id="annotations-pane">
      <div style="padding-right: 20px; padding-top: 20px">
        <action class="close-icon" @click="changeShowAnnotationsPane(false)">
          <i class="fa fa-times"><span class="alt-text">Close</span></i>
        </action>
      </div>

      <div class="annotation-pane-header">
        <h2 class="annotations-pane-title">SciLite annotations</h2>
        <div class="annotations-export-title">
          <a @click="exportAnnotations">
            <i class="fa fa-solid fa-file-export"></i>&nbsp;&nbsp;Export
            annotations
          </a>
        </div>
      </div>
      <div class="tabs-container">
        <button
          v-if="annotationsCount.fulltextCount > 0"
          id="full_text_button"
          style="margin-right: 10px; margin-left: 20px"
          class="tab-button active"
          @click="showTab('full_text')"
        >
          {{ abstract.hasFulltext ? 'In full text' : 'In abstract' }}
        </button>
        <button
          v-if="annotationsCount.supplementaryFilesCount > 0"
          id="supp_files_button"
          class="tab-button"
          @click="showTab('supp_files')"
        >
          In supp. files
        </button>
        <div id="full_text" class="tab-content active">
          <div v-if="canHighlight">
            <i class="fa fa-solid fa-highlighter"></i>&nbsp; The terms below are
            text mined from the
            {{ abstract.hasFulltext ? 'full text' : 'abstract' }}. To highlight,
            select an entity and click "Find".
            <br />
          </div>
          <notification-list :notification-list="getNotificationList" :show="3">
            <template slot-scope="{ notification }">
              <template v-if="notification === 'ppr'">
                The terms below have been found within
                {{ abstract.hasFulltext ? 'full text' : 'abstract' }} of this
                preprint. However, due to licensing restrictions, the terms
                cannot be highlighted in the full text.
              </template>
              <template v-else-if="notification === 'restrictions'">
                The terms below have been found within the
                {{ abstract.hasFulltext ? 'full text' : 'abstract' }} of this
                article. Due to licensing restrictions, they cannot be
                highlighted within the text.
              </template>
              <template v-else-if="notification === 'no'">
                No key terms have yet been identified and submitted by curation
                or text mining groups.
              </template>
              <template v-else-if="notification === 'metagenomics'">
                Other annotations, not related to metagenomics, can be found in
                the
                <a :href="annotationAPI" target="_blank">Annotations API</a>.
              </template>
            </template>
          </notification-list>
          <template v-if="annotationsLoaded && hasAnnotations">
            <div
              v-for="(cat, index) in nonEmptyCategories.filter(
                (item) => item.containsOnlyBiostudiesData === false
              )"
              :key="cat.title"
            >
              <h4 :class="{ 'can-highlight': canHighlight }">
                <input
                  v-if="canHighlight"
                  :id="cat.title"
                  type="checkbox"
                  :checked="cat | allLabelsSelected"
                  @click="checkCategory(cat)"
                />
                <label
                  :id="'annotation--main--type-' + cat.title"
                  class="semi-bold"
                  :for="cat.title"
                  v-html="cat.title"
                ></label>
                <sup class="cat-desc">
                  <tooltip v-if="cat.description" placement="top">
                    <i slot="trigger" class="far fa-question-circle" />
                    <b>{{ cat.title }}</b
                    >: {{ cat.description }}
                  </tooltip>
                </sup>
              </h4>
              <hr />
              <div class="labels">
                <div
                  v-for="label in cat.labels
                    .filter(
                      (item) =>
                        item.isBiostudies === false ||
                        item.haveMultipleProviders === true
                    )
                    .slice(0, labelShow[index])"
                  :key="label.title"
                  :class="['label', { 'can-highlight': canHighlight }]"
                >
                  <input
                    v-if="canHighlight"
                    :id="label.title"
                    type="checkbox"
                    :checked="label.selected"
                    @click="checkLabel(cat, label)"
                  />

                  <label
                    v-if="
                      label.isBiostudies === false ||
                      label.haveMultipleProviders === true
                    "
                    :for="canHighlight && label.title"
                  >
                    <tooltip
                      placement="left"
                      :offset="canHighlight ? '28px' : undefined"
                      :delay-on-mouse-out="0"
                      max-width="none"
                      @show="showTooltip(cat, label)"
                    >
                      <action
                        :id="'annotation--sub--type-' + label.title"
                        slot="trigger"
                        :class="['regular', { 'non-clickable': !canHighlight }]"
                      >
                        {{ label.title }} (<template
                          v-if="
                            canHighlight && label.nextAnnotationIndex !== false
                          "
                          ><span class="bold">{{
                            label.nextAnnotationIndex + 1
                          }}</span
                          >/</template
                        >{{
                          canHighlight
                            ? label.annotations.length
                            : label.frequency || label.annotations.length || 1
                        }})
                      </action>
                      <div
                        v-for="provider in label.providers.filter(
                          (item) => item.provider !== 'Biostudies'
                        )"
                        :key="provider.provider"
                      >
                        <div class="small italic">
                          Provided by {{ provider.provider }}
                        </div>
                        <h5 class="annotation-tooltip-label-title">
                          {{ label.title }}
                        </h5>
                        <div v-for="tag in provider.tags" :key="tag.name">
                          <a
                            class="semi-bold"
                            :href="getTagLink(tag)"
                            target="_blank"
                            >{{ getTagLinkLabel(tag) }}
                            <i class="fas fa-external-link-alt"></i
                          ></a>
                          <view-structure
                            v-if="
                              label.hovered && getBioStructureInfoFromTag(tag)
                            "
                            :link-scheme="
                              getBioStructureInfoFromTag(tag).linkScheme
                            "
                            :link-id="getBioStructureInfoFromTag(tag).linkId"
                            :collapsible="false"
                            :expand-all="true"
                            :hide-canvas-controls="[
                              'expand',
                              'selection',
                              'animation',
                            ]"
                          />
                        </div>
                      </div>
                    </tooltip>
                  </label>

                  <span v-if="label.selected" class="nav-label">
                    <action
                      v-if="label.nextAnnotationIndex === false"
                      icon-pos="right"
                      @click="
                        navAnnotation(label)
                        registerMatomoEventForAnnotations(
                          'Annotations - Find',
                          'Find'
                        )
                      "
                    >
                      Find<i slot="icon" class="fas fa-chevron-right" />
                    </action>
                    <template v-else>
                      <template v-if="label.annotations.length > 1">
                        <action
                          :disabled="label.nextAnnotationIndex === 0"
                          class="prev"
                          @click="
                            navAnnotation(label, true)
                            registerMatomoEventForAnnotations(
                              'Annotations - Find',
                              'Prev'
                            )
                          "
                        >
                          <i slot="icon" class="fas fa-chevron-left"
                        /></action>
                      </template>
                      <action
                        @click="
                          navAnnotation(label, false)
                          registerMatomoEventForAnnotations(
                            'Annotations - Find',
                            'Next'
                          )
                        "
                      >
                        <i slot="icon" class="fas fa-chevron-right" />
                      </action>
                    </template>
                  </span>
                </div>
                <div
                  v-if="
                    cat.labels.filter(
                      (label) =>
                        label.isBiostudies === false ||
                        label.haveMultipleProviders === true
                    ).length > labelShowLimit
                  "
                >
                  <action icon-pos="right" @click="showLabel(cat, index)">
                    {{
                      labelShow[index] === labelShowLimit
                        ? 'Show all ' +
                          cat.labels.filter(
                            (label) =>
                              label.isBiostudies === false ||
                              label.haveMultipleProviders === true
                          ).length +
                          ' terms'
                        : 'Show less'
                    }}<i
                      slot="icon"
                      :class="[
                        'fas',
                        labelShow[index] === labelShowLimit
                          ? 'fa-caret-right'
                          : 'fa-caret-up',
                      ]"
                    />
                  </action>
                </div>
              </div>
            </div>
          </template>
          <loading v-else />
        </div>
        <div id="supp_files" class="tab-content">
          The terms below are text mined from the text-based supplementary
          files.
          <br />
          <template v-if="annotationsLoaded && hasAnnotations">
            <div
              v-for="(cat, index) in nonEmptyCategories.filter(
                (item) => item.containsOnlyNonBiostudiesData === false
              )"
              :key="cat.title"
            >
              <h4>
                <label
                  :id="'annotation--main--type-' + cat.title"
                  class="semi-bold"
                  :for="cat.title"
                  v-html="cat.title"
                ></label>
                <sup class="cat-desc">
                  <tooltip v-if="cat.description" placement="top">
                    <i slot="trigger" class="far fa-question-circle" />
                    <b>{{ cat.title }}</b
                    >: {{ cat.description }}
                  </tooltip>
                </sup>
              </h4>
              <hr />
              <div class="labels">
                <div
                  v-for="label in cat.labels
                    .filter(
                      (item) =>
                        item.isBiostudies === true ||
                        item.haveMultipleProviders === true
                    )
                    .slice(0, labelShow[index])"
                  :key="label.title"
                  :class="['label']"
                >
                  <div
                    v-if="
                      label.isBiostudies === true ||
                      label.haveMultipleProviders === true
                    "
                  >
                    <tooltip
                      placement="left"
                      :delay-on-mouse-out="0"
                      max-width="none"
                      @show="showTooltip(cat, label)"
                    >
                      <action
                        :id="'annotation--sub--type-' + label.title"
                        slot="trigger"
                        :class="['regular', { 'non-clickable': !canHighlight }]"
                      >
                        {{ label.title }}
                        ({{ label.supplementaryFrequency }})
                      </action>
                      <div
                        v-for="provider in label.providers.filter(
                          (item) => item.provider === 'Biostudies'
                        )"
                        :key="provider.provider"
                      >
                        <div class="small italic">
                          Provided by {{ provider.provider }}
                        </div>
                        <h5 class="annotation-tooltip-label-title">
                          {{ label.title }}
                        </h5>
                        <div v-for="tag in provider.tags" :key="tag.name">
                          <a
                            class="semi-bold"
                            :href="getTagLink(tag)"
                            target="_blank"
                            >{{ getTagLinkLabel(tag) }}
                            <i class="fas fa-external-link-alt"></i
                          ></a>
                          <view-structure
                            v-if="
                              label.hovered && getBioStructureInfoFromTag(tag)
                            "
                            :link-scheme="
                              getBioStructureInfoFromTag(tag).linkScheme
                            "
                            :link-id="getBioStructureInfoFromTag(tag).linkId"
                            :collapsible="false"
                            :expand-all="true"
                            :hide-canvas-controls="[
                              'expand',
                              'selection',
                              'animation',
                            ]"
                          />
                        </div>
                      </div>
                      <h5 class="annotation-tooltip-label-title">
                        Occurences in the supp. files:
                      </h5>
                      <div v-for="file in label.files" :key="file.fileName">
                        <a
                          class="semi-bold"
                          target="_blank"
                          :href="constructBiostudiesFileUrl(file.fileName)"
                        >
                          {{ file.fileName }}
                          ({{ file.supplementaryFrequency }})
                        </a>
                      </div>
                    </tooltip>
                  </div>
                </div>
                <div
                  v-if="
                    cat.labels.filter(
                      (item) =>
                        item.isBiostudies === true ||
                        item.haveMultipleProviders === true
                    ).length > labelShowLimit
                  "
                >
                  <action icon-pos="right" @click="showLabel(cat, index)">
                    {{
                      labelShow[index] === labelShowLimit
                        ? 'Show all ' +
                          cat.labels.filter(
                            (item) =>
                              item.isBiostudies === true ||
                              item.haveMultipleProviders === true
                          ).length +
                          ' terms'
                        : 'Show less'
                    }}<i
                      slot="icon"
                      :class="[
                        'fas',
                        labelShow[index] === labelShowLimit
                          ? 'fa-caret-right'
                          : 'fa-caret-up',
                      ]"
                    />
                  </action>
                </div>
              </div>
            </div>
          </template>
          <loading v-else />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.grecaptcha-badge {
  height: 0 !important;
  width: 0 !important;
  visibility: hidden;
}
.scilite-annotation {
  cursor: pointer;
  background-color: #fff4ab;
  &:hover {
    background-color: #f8f0ce;
  }
  &.current {
    background-color: #e9d991;
  }
}
.show-report-modal h4:first-child {
  margin-top: 0 !important;
}
.annotation-tooltip-input {
  margin-top: $base-unit * 2;
  width: 100%;
}
.annotation-tooltip-btn {
  margin-top: $base-unit * 4;
}
.annotation-tooltip-label-title {
  margin-top: $base-unit !important;
  margin-bottom: $base-unit !important;
  font-style: normal !important; /* compromise */
}
.annotation-tooltip-footer {
  margin: ($base-unit * 3) 0 !important;
}
.annotation-popup-separator {
  margin: 0 ($base-unit * 3);
  border-right: thin solid lighten($epmc-darker-grey, 50%);
}
.share-link-copied {
  float: right;
  .fa-check {
    color: $epmc-dark-green;
  }
}
.reCAPTCHA-claim-separator {
  margin-top: $base-unit * 8 !important;
  margin-bottom: $base-unit !important;
}
.tabs-container {
  margin-top: $base-unit * 6;
}
.tab-button {
  display: inline-block;
  border: thin solid lighten($epmc-darker-grey, 50%) !important;
  color: black !important;
  font-weight: 600 !important;
  background: lighten($epmc-darker-grey, 60%) !important;
  border-radius: 0 !important;
  margin-bottom: $base-unit - 5;
}
.tab-button.active {
  border-bottom: none !important;
  background: white !important;
}
.tab-content {
  display: none;
  border: none;
  border-top: thin solid lighten($epmc-darker-grey, 50%) !important;
  padding-top: $base-unit * 3;
  padding-left: $base-unit * 5;
  padding-bottom: $base-unit * 16;
  padding-right: $base-unit * 5;
}
.tab-content.active {
  display: block;
}
</style>
<style lang="scss" scoped>
.annotation-pane-header {
  padding-top: $base-unit * 3;
  padding-left: $base-unit * 5;
  padding-right: $base-unit * 5;
}
#annotations-pane-wrapper {
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: ($base-unit * 0.25) ($base-unit * 0.25) $base-unit
    ($base-unit * 0.25) lighten($epmc-darker-grey, 10%);
  width: auto;
  overflow: visible !important;
  @media screen and (max-width: $breakpoint-small) {
    display: none;
  }
  #annotations-pane {
    float: right;
    width: $base-unit * 100;
    @media screen and (max-width: $base-unit * 368) {
      width: $base-unit * 85 !important;
    }
    @media screen and (max-width: $base-unit * 325) {
      width: $base-unit * 70 !important;
    }
    height: 100vh;
    overflow: auto;
    box-sizing: border-box;
    background-color: $epmc-white;
    .close-icon {
      float: right;
      font-size: $base-unit * 4;
    }
    .annotations-pane-title {
      margin-bottom: 0;
      margin-top: $base-unit * 5;
      @media screen and (max-width: $base-unit * 325) {
        font-size: $base-unit * 5;
      }
    }
    .annotations-export-title {
      color: #20699c;
      font-size: 15px;
      font-weight: 600;
    }
    h4 {
      margin-bottom: $base-unit;
      &.can-highlight {
        padding-left: $base-unit * 6;
        input[type='checkbox'] {
          margin-left: $base-unit * -5;
        }
      }
      input[type='checkbox'] {
        vertical-align: 12%;
      }
      label {
        margin-left: $base-unit * 0.5;
      }
      .cat-desc {
        margin-left: $base-unit;
      }
    }
    .labels {
      margin-top: $base-unit;
      .label {
        line-height: 1 !important;
        &:first-child {
          margin-top: $base-unit * 2;
        }
        &:not(:last-child) {
          margin-bottom: $base-unit * 2;
        }
        &::after {
          content: '';
          clear: both;
          display: block;
        }
        &.can-highlight {
          padding-left: $base-unit * 6;
          input[type='checkbox'] {
            margin-left: $base-unit * -5;
          }
        }
      }
      .non-clickable {
        cursor: default;
        &:hover {
          color: $epmc-darker-blue;
        }
      }
      .nav-label {
        float: right;
        .prev {
          margin-right: $base-unit * 4;
        }
      }
    }
  }
}
</style>
