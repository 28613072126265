<script>
import { mapState, mapActions } from 'vuex'
import { Loading } from 'epmc-patterns/components/v2'
import DataLinkProvider from '../DataLinkProvider'

export default {
  components: { Loading, DataLinkProvider },
  props: {
    dataTags: {
      type: Array,
      default: () => [],
    },
    sectionId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      loadingDataLinks: false,
    }
  },
  computed: {
    ...mapState('article/dataLinks', ['dataLinks']),
    ...mapState('article/sections', ['sections']),
    ...mapState('article/abstract', ['abstract']),
    sectionAvailableTags() {
      return this.dataTags.filter((tag) =>
        Object.keys(this.dataLinks).includes(tag.id)
      )
    },
  },
  async created() {
    if (!Object.keys(this.dataLinks).length) {
      this.loadingDataLinks = true
      await this.loadDataLinks()
      this.loadingDataLinks = false
    }
  },
  methods: {
    ...mapActions('article/dataLinks', ['loadDataLinks']),
  },
}
</script>
<template>
  <div>
    <loading v-if="loadingDataLinks" />
    <div v-else>
      <div
        v-for="dataTag in sectionAvailableTags"
        :id="dataTag.id"
        :key="dataTag.id"
      >
        <h3 v-if="dataTag.title" class="no-margin-bottom">
          {{ dataTag.title }}
        </h3>
        <p
          v-if="dataTag.descriptiveText"
          class="small italic no-margin-bottom sub-descriptive-text"
        >
          {{ dataTag.descriptiveText }}
        </p>
        <div class="data-tag-content">
          <data-link-provider
            v-for="linkProvider in dataLinks[dataTag.id]"
            :key="linkProvider.name"
            :provider="linkProvider"
            :expand-all="abstract.botRequest"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
h3 {
  margin-top: $base-unit * 6;
}
.data-tag-content {
  border: thin solid lighten($epmc-darker-grey, 50%);
  padding: 0 ($base-unit * 5) ($base-unit * 2);
  margin: ($base-unit * 4) auto ($base-unit * 8);
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.sub-descriptive-text {
  margin-top: 0px !important;
}
</style>
