<script>
import { mapState, mapActions } from 'vuex'
import { scrollTo } from 'epmc-patterns/helpers'
import { fetchCitingArticles, fetchYearlyCitations } from '@/api'
import { registerMatomoEvent } from '@/helpers/matomo'
import {
  List,
  Chart,
  Action,
  FeaturedNumber,
  Tooltip,
  Loading,
} from 'epmc-patterns/components/v2'
import Citation from '@/templates/Citation'
import DataProviderCollection from './DataProviderCollection'

export default {
  components: {
    Chart,
    Citation,
    FeaturedNumber,
    List,
    DataProviderCollection,
    Action,
    Tooltip,
    Loading,
  },
  props: {
    dataTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      citingArticles: [],
      citingDbRecords: [],
      citedByCount: 0,
      dbRecordsCount: 0,
      reviewsCount: 0,
      altmetric: [],
      loading: false,
      labels: [],
      datasets: [],
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: (value) => {
                  if (value % 1 === 0) {
                    return value
                  }
                },
              },
            },
          ],
        },
      },
      update: false,

      graphCitationCount: 0,
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('article/dataLinks', ['dataLinks']),
    ...mapState('article/sections', ['sections']),
    ...mapState('article/evaluations', ['evaluationList']),
    citingLink() {
      return {
        name: 'search',
        query: {
          query: 'CITES:' + this.abstract.id + '_' + this.abstract.source,
          page: 1,
          sortBy: 'Date+DESC',
        },
      }
    },
    showOtherCitations() {
      const dataTags = Object.keys(this.dataLinks)
      const sectionTagIds = this.dataTags.map((tagObj) => tagObj.id)
      return dataTags.find((tag) => sectionTagIds.includes(tag))
    },
  },
  async created() {
    const { abstract } = this
    this.citedByCount = abstract.citedByCount
    if (
      this.abstract.dataLinksTagsList &&
      !Object.keys(this.dataLinks).length
    ) {
      this.loading = true
      await this.loadDataLinks()
    }
    this.dbRecordsCount = this.dataLinks['related_data']
      ? this.dataLinks['related_data'].reduce(
          (count, obj) => count + obj.links.length,
          0
        )
      : 0

    if (
      this.abstract.hasEvaluations &&
      this.abstract.hasEvaluations.toLowerCase() === 'y' &&
      !Object.keys(this.evaluationList.evaluationsGroup).length
    ) {
      this.loading = true
      await this.loadEvaluations()
    }
    this.reviewsCount = this.evaluationList.length

    this.altmetric = this.dataLinks['altmetrics']
      ? this.dataLinks['altmetrics'][0].links
      : []
    if (abstract.citedByCount > 0) {
      fetchCitingArticles(abstract.source, abstract.id).then((response) => {
        this.citingArticles = response.resultList.result
        this.citedByCount = response.hitCount
        this.loading = false
      })
    } else {
      this.loading = false
    }
  },
  mounted() {
    if (this.citedByCount > 2) {
      this.sendThis()
    }
  },
  methods: {
    ...mapActions('article/dataLinks', ['loadDataLinks']),
    ...mapActions('article/evaluations', ['loadEvaluations']),
    getAbstractId(source, abstract) {
      if ('CTX' === source) {
        return abstract.id.toLowerCase()
      }
      return abstract.id
    },
    sendThis() {
      const abstractId = this.getAbstractId(this.abstract.source, this.abstract)
      fetchYearlyCitations(this.abstract.source, abstractId).then(
        (response) => {
          const citations = response
          this.graphCitationCount = citations.length

          this.labels = citations.map((citation) => citation.id.pubyear)

          const citingArticlesCounts = citations.map(
            (citation) => citation.citingArticlesCount
          )

          const numberOfYears = this.labels.length
          const mostRecentYear = this.labels[numberOfYears - 1]
          const currentYear = new Date().getFullYear()
          if (mostRecentYear === currentYear && numberOfYears >= 2) {
            const citingArticlesCounts2 = citingArticlesCounts.map(
              (count, index) => {
                if (
                  index !== citingArticlesCounts.length - 1 &&
                  index !== citingArticlesCounts.length - 2
                )
                  return NaN
                return count
              }
            )
            citingArticlesCounts[citingArticlesCounts.length - 1] = NaN
            this.datasets.push(
              { label: 'Citations', data: citingArticlesCounts },
              {
                label: 'Citations',
                data: citingArticlesCounts2,
                borderDash: [2, 2],
              }
            )
          } else {
            this.datasets.push({
              label: 'Citations',
              data: citingArticlesCounts,
            })
          }
          this.update = true
        }
      )
    },
    jumpTo(e, targetId, subSectionId) {
      const targetSection = this.sections.find((sec) => sec.id === targetId)
      if (targetSection) {
        targetSection.visible = true
        if (subSectionId) {
          scrollTo('#' + targetId, '#' + targetId + ' #' + subSectionId)
        } else {
          scrollTo('#' + targetId, '#' + targetId)
        }
        history.pushState(null, null, '#' + targetId)
        this.sections.forEach((sec) => {
          sec.inFrame = false
        })
        targetSection.inFrame = true
      } else {
        scrollTo('#' + targetId, '#' + targetId)
      }
      this.registerMatomoEventForImpact(targetId)
    },
    registerMatomoEventForImpact(targetId) {
      registerMatomoEvent('Article', 'Citations and impact', targetId)
    },
  },
}
</script>
<template>
  <div class="impact">
    <p v-if="!citedByCount">This article has not been cited yet.</p>

    <template
      v-if="
        citedByCount ||
        dbRecordsCount ||
        reviewsCount ||
        altmetric.length > 0 ||
        abstract.scite
      "
    >
      <h3>Impact metrics</h3>
      <div class="impact-metrics">
        <div
          v-if="citedByCount || dbRecordsCount || reviewsCount"
          class="impact-counters"
        >
          <div v-if="citedByCount" id="article--impact--subItem-citedByCount">
            <featured-number
              :number="citedByCount"
              :below-text="'Citation' + (citedByCount !== 1 ? 's' : '')"
              size="large"
            />
            <action
              id="article--impact--jumpToLink-citations"
              @click="jumpTo($event, 'article-citations-header')"
            >
              Jump to Citations
            </action>
          </div>
          <div
            v-if="dbRecordsCount"
            id="article--impact--subItem-dbRecordsCount"
          >
            <featured-number
              :number="dbRecordsCount"
              :below-text="'Data citation' + (dbRecordsCount !== 1 ? 's' : '')"
              size="large"
            />
            <action
              id="article--impact--jumpToLink-data"
              @click="jumpTo($event, 'data', 'related_data')"
            >
              Jump to Data
            </action>
          </div>
          <div v-if="reviewsCount" id="article--impact--subItem-reviewsCount">
            <featured-number
              :number="reviewsCount"
              :below-text="'Review' + (reviewsCount !== 1 ? 's' : '')"
              size="large"
            />
            <action
              id="article--impact--jumpToLink-reviews"
              @click="jumpTo($event, 'reviews')"
            >
              Jump to Reviews
            </action>
          </div>
        </div>
        <h4 v-if="citedByCount > 2" id="citedByCount">
          Citations of article over time
        </h4>
        <chart
          v-if="citedByCount > 2"
          v-show="graphCitationCount"
          :labels="labels"
          :datasets="datasets"
          :options="options"
          :update="update"
          aria-label="Citation chart"
          role="img"
        />
        <loading v-if="loading" />
        <template v-if="!loading && altmetric.length > 0">
          <h4>Alternative metrics</h4>
          <div
            v-for="altmetricItem in altmetric"
            :key="altmetricItem.url"
            class="altmetric item-container"
          >
            <a
              :href="altmetricItem.url"
              class="small"
              :aria-label="'Altmetric item for ' + altmetricItem.url"
            >
              <img
                :src="altmetricItem.img"
                :alt="'Altmetric item for ' + altmetricItem.url"
              />
            </a>
            <div class="small">
              <span class="semi-bold">Altmetric</span><br />
              <span class="italic small"
                >Discover the attention surrounding your research</span
              ><br />
              <a :href="altmetricItem.url" class="small"
                >{{ altmetricItem.url }}<i class="fas fa-external-link-alt"
              /></a>
            </div>
          </div>
        </template>
        <template v-if="abstract.scite">
          <h4>
            <span id="scite-title">
              Smart citations by scite.ai
              <tooltip>
                <i slot="trigger" class="far fa-question-circle" />
                Smart citations by
                <a href="//scite.ai">scite.ai</a> include citation statements
                extracted from the full text of the citing article. The number
                of the statements may be higher than the number of citations
                provided by EuropePMC if one paper cites another multiple times
                or lower if scite has not yet processed some of the citing
                articles.
              </tooltip>
            </span>
            <div class="small regular">
              <i
                >Explore citation contexts and check if this article has been
                supported or disputed.</i
              >
            </div>
            <a
              class="small regular"
              :href="'//scite.ai/reports/' + abstract.doi"
            >
              {{ 'https://scite.ai/reports/' + abstract.doi }}
              <i class="fas fa-external-link-alt" />
            </a>
          </h4>
          <div id="scite-panel">
            <div class="col">
              <div class="semi-bold">Supporting</div>
              <div class="semi-bold">Mentioning</div>
              <div class="semi-bold">Contrasting</div>
            </div>
            <div class="col">
              <div>
                <i class="far fa-check-circle supporting-icon"></i>
              </div>
              <div>
                <i class="far fa-circle mentioning-icon"></i>
                <span class="mentioning-icon-inside"></span>
              </div>
              <div>
                <i class="far fa-question-circle contradicting-icon"></i>
              </div>
            </div>
            <div class="col">
              <div class="right semi-bold">
                {{ abstract.scite.supporting }}
              </div>
              <div class="right semi-bold">
                {{ abstract.scite.mentioning }}
              </div>
              <div class="right semi-bold">
                {{ abstract.scite.contradicting }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>

    <h3 v-if="citedByCount" id="article-citations-header">Article citations</h3>
    <loading v-if="loading" />
    <div v-if="!loading && citedByCount" id="article-citations">
      <list :list="citingArticles">
        <citation
          :id="'article--impact--citation-' + item.id"
          slot-scope="{ item }"
          :citation="item"
          heading-level="h4"
        />
      </list>
      <template v-if="citedByCount > 5">
        <hr />
        <action
          :href="
            '/search?query=' +
            citingLink.query.query +
            '&page=' +
            citingLink.query.page +
            '&sortby=' +
            citingLink.query.sortBy
          "
          @click="registerMatomoEventForImpact('View All Citations')"
        >
          Go to all ({{ citedByCount.toLocaleString() }}) article citations
        </action>
      </template>
    </div>
    <h3 v-if="showOtherCitations">Other citations</h3>
    <data-provider-collection
      v-if="showOtherCitations"
      class="other-citations"
      :data-tags="dataTags"
    />
  </div>
</template>
<style scoped lang="scss">
.impact {
  h3 {
    margin-top: $base-unit * 6;
  }
  .impact-metrics {
    h4 {
      margin: ($base-unit * 4) auto;
    }
    .impact-counters {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
      flex-wrap: wrap;
      & > * {
        flex: 1 0 ($base-unit * 30);
        text-align: center;
        margin: $base-unit * 4;
      }
    }
    #citationGraph {
      width: 100% !important;
    }
    .altmetric {
      display: flex;
      margin: ($base-unit * 3) auto 0;
      & > * {
        margin: 0 ($base-unit * 2);
      }
      i {
        margin-left: $base-unit;
      }

      img {
        height: $base-unit * 16;
      }
    }
  }

  .impact-metrics,
  #article-citations {
    border: thin solid lighten($epmc-darker-grey, 50%);
    padding: 0 ($base-unit * 5) ($base-unit * 5);
    margin: ($base-unit * 3) auto ($base-unit * 8);
  }
  #article-citations {
    a {
      display: block;
      text-align: center;
    }
    hr {
      margin: ($base-unit * 2) auto;
    }
  }

  #scite-title {
    display: inline-block;
    margin-bottom: $base-unit;
  }
  #scite-panel .col {
    display: inline-block;
    margin-right: $base-unit * 2;
    .right {
      text-align: right;
    }
    .supporting-icon {
      color: $epmc-orcid-green;
    }
    .mentioning-icon {
      color: lighten($epmc-darker-grey, 30%);
    }
    .contradicting-icon {
      color: #66a3ff; // need a color var?
    }
    .mentioning-icon-inside {
      position: absolute;
      display: inline-block;
      margin-top: $base-unit * 1.75;
      margin-left: $base-unit * -2;
      transform: rotate(45deg);
      width: $base-unit * 0.5;
      height: $base-unit * 2.25;
      background-color: lighten($epmc-darker-grey, 30%);
    }
  }
}
</style>
