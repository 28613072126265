<script>
import { mapState, mapMutations } from 'vuex'
import { fetchReferences } from '@/api'
import { List, Action } from 'epmc-patterns/components/v2'
import Citation from '@/templates/Citation'

const MAX_REF_LOADED_ONCE = 10

export default {
  components: {
    Action,
    Citation,
    List,
  },
  data() {
    return {
      referencesCount: 0,
      referenceArticles: [],
      page: 1,
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    showShowMore() {
      return this.referencesCount > MAX_REF_LOADED_ONCE
    },
    remainingArticles() {
      return this.referencesCount - this.referenceArticles.length
    },
    showLabel() {
      const { remainingArticles, referencesCount, referenceArticles } = this
      let label = 'Show '
      if (remainingArticles) {
        label +=
          remainingArticles < MAX_REF_LOADED_ONCE
            ? remainingArticles
            : MAX_REF_LOADED_ONCE
        label +=
          ' more ' + (remainingArticles === 1 ? 'reference' : 'references')
        label +=
          ' (' + referenceArticles.length + ' of ' + referencesCount + ')'
      } else {
        label += 'less references'
      }
      return label
    },
  },
  created() {
    this.loadReferences()
  },
  methods: {
    ...mapMutations('article/sections', ['changeSectionVariable']),
    loadReferences() {
      const { abstract, page } = this
      fetchReferences(abstract.source, abstract.id, page)
        .then((response) => {
          if (response.hitCount > 0) {
            // hitCount only updated once
            if (page === 1) {
              this.referencesCount = response.hitCount
            }
            this.referenceArticles = this.referenceArticles.concat(
              response.referenceList.reference
            )
          } else {
            this.changeSectionVariable({ id: 'references', render: false })
          }
        })
        .catch(() => {
          this.changeSectionVariable({ id: 'references', render: false })
        })
    },
    lazyLoadReferences() {
      if (this.remainingArticles) {
        this.page++
      } else {
        this.page = 1
        this.referenceArticles = []
      }
      this.loadReferences()
    },
  },
}
</script>
<template>
  <div v-if="referencesCount" class="references">
    <p class="small italic">
      Articles referenced by this article ({{ referencesCount }})
    </p>
    <list :list="referenceArticles">
      <citation slot-scope="{ item }" :citation="item" />
    </list>
    <div v-if="showShowMore">
      <hr />
      <action
        class="references--articles-link"
        icon-pos="right"
        @click="lazyLoadReferences"
      >
        {{ showLabel
        }}<i
          slot="icon"
          :class="['fas', remainingArticles ? 'fa-caret-up' : 'fa-caret-right']"
        />
      </action>
    </div>
  </div>
</template>
