<script>
import { containEmail, stripEmail } from 'epmc-patterns/helpers'
import { registerMatomoEvent } from '@/helpers/matomo'
import MetadataAuthorPopup from './MetadataAuthorPopup'

export default {
  components: {
    MetadataAuthorPopup,
  },
  props: {
    affiliations: {
      type: Array,
      default: () => [],
    },
    author: {
      type: Object,
      default: () => {},
    },
    last: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMetadataAuthorPopup: false,
    }
  },
  computed: {
    authorLink() {
      const { authorId } = this.author
      const to = authorId
        ? {
            name: 'authorProfile',
            params: { orcid: authorId.value.toUpperCase() },
          }
        : { name: 'search', query: { query: 'AUTH:"' + this.authorName + '"' } }
      return to
    },
    authorName() {
      let authorName = ''
      if (this.author.firstName && this.author.lastName) {
        authorName = this.author.firstName + ' ' + this.author.lastName
      } else if (this.author.fullName) {
        authorName = this.author.fullName
      } else if (this.author.collectiveName) {
        authorName = this.author.collectiveName
      }
      return authorName
    },
    shortAuthorName() {
      let shortAuthorName = ''
      if (this.author.fullName) {
        shortAuthorName = this.author.fullName
      } else if (this.author.collectiveName) {
        shortAuthorName = this.author.collectiveName
      }
      return shortAuthorName
    },
  },
  methods: {
    containEmail,
    stripEmail,
    trackPopup() {
      registerMatomoEvent('Article', 'Author name ', 'Popup opened')
    },
    openMetadataAuthorPopup() {
      this.showMetadataAuthorPopup = true
      registerMatomoEvent('Article', 'Author name ', 'Popup opened')
    },
    getAffiliationsByAuthor(currentIndex, affAuthor) {
      const { affiliations } = this
      let a = false
      for (let index = currentIndex; index < affiliations.length; index++) {
        const element = affiliations[index + 1]
        if (element)
          a = affAuthor.authorAffiliationDetailsList.authorAffiliation.find(
            (item) => stripEmail(item.affiliation) === element.name
          )
        if (a) return ','
      }
    },
  },
}
</script>
<template>
  <span>
    <div
      slot="trigger"
      class="metadata--author-block"
      itemprop="author"
      itemscope
      itemtype="http://schema.org/Person"
    >
      <!-- DO NOT BREAK THIS LINE -->
      <a
        :id="`article--author--name-${
          author.authorId
            ? author.authorId.value
            : authorName.replace(/[^a-zA-Z]/g, '-')
        }`"
        :class="['metadata--author-link']"
        @click.prevent="openMetadataAuthorPopup()"
        ><span class="metadata--author-name" itemprop="name">{{
          shortAuthorName
        }}</span
        >{{ ' '
        }}<template v-if="author.affiliation">
          <sup>
            <span
              v-for="(affiliation, index) in affiliations"
              :key="index"
              class="affiliation-reference"
            >
              <span
                v-if="
                  author.authorAffiliationDetailsList.authorAffiliation.find(
                    (item) => stripEmail(item.affiliation) === affiliation.name
                  )
                "
                class="book-tooltip"
              >
                {{ index + 1 }}{{ getAffiliationsByAuthor(index, author) }}
              </span>
            </span>
          </sup>
          <i
            v-if="containEmail(author.affiliation)"
            class="fas fa-envelope" /></template
        ><i v-if="author.authorId" class="fab fa-orcid" /></a
      ><span v-if="!last">, </span>
    </div>
    <metadata-author-popup
      v-if="showMetadataAuthorPopup"
      :author="author"
      :author-name="authorName"
      @close="showMetadataAuthorPopup = false"
    />
  </span>
</template>
<style scoped lang="scss">
.metadata--author-block {
  display: contents;
}
a.metadata--author-link {
  i {
    padding-left: 2px;
    font-size: 0.9em;
    vertical-align: 40%;
    color: $epmc-dark-blue;

    &.fa-orcid {
      vertical-align: 35%;
    }
  }
  &:hover {
    text-decoration: none;

    .metadata--author-name {
      text-decoration: underline;
    }

    i {
      opacity: 0.8;
    }
  }
}
.affiliation-reference {
  display: inline-flex;
  color: $epmc-darker-grey;
}
</style>
