<script>
// what is left***
// 1. clean viewer
// 2. lazy load
import { Action, Loading } from 'epmc-patterns/components/v2'
import { addLink, addScript } from '@/helpers/load-script-and-style'

const checkImage = (src) => {
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve(src)
    }
    img.onerror = () => {
      resolve('')
    }
    img.src = src
  })
}

export default {
  components: {
    Action,
    Loading,
  },
  props: {
    linkId: {
      type: String,
      default: '',
    },
    linkScheme: {
      type: String,
      default: '',
    },
    // init state
    expandAll: {
      type: Boolean,
      default: false,
    },
    // will not change
    collapsible: {
      type: Boolean,
      default: true,
    },
    hideCanvasControls: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showStructure: this.expandAll,
      structureShown: false,
      loading: true,
      canvas: null,
      chemImageSrc: '',
    }
  },
  async created() {
    if (this.linkScheme !== 'PDB') {
      const srcToCheck =
        'https://www.ebi.ac.uk/chebi/displayImage.do?defaultImage=true&imageIndex=0&chebiId=' +
        this.linkId +
        '&dimensions=300'
      this.chemImageSrc = await checkImage(srcToCheck)
    }
    this.loading = false
  },
  async mounted() {
    await this.$nextTick()
    if (this.linkScheme === 'PDB') {
      this.loadPDBViewer(() => {
        this.loading = false
      })
    }
  },
  methods: {
    async expandStructure() {
      this.showStructure = !this.showStructure
      this.structureShown = true
      if (this.linkScheme === 'PDB' && this.showStructure) {
        await this.$nextTick()
        this.loadPDBViewer(() => {
          this.loading = false
        })
      }
    },
    esc() {
      this.canvas.toggleExpanded(false)
    },
    async loadPDBViewer(cb) {
      const viewerContainer = this.$refs.pdbViewer
      if (
        viewerContainer &&
        !viewerContainer.getElementsByClassName('.msp-plugin').length
      ) {
        addLink({
          href: 'https://www.ebi.ac.uk/pdbe/pdb-component-library/css/pdbe-molstar-light-1.1.0.css',
        })
        // can we use npm
        await addScript({
          src: 'https://www.ebi.ac.uk/pdbe/pdb-component-library/js/pdbe-molstar-plugin-1.1.0.js',
        })
        // eslint-disable-next-line
        const viewerInstance = new PDBeMolstarPlugin()
        const options = {
          moleculeId: this.linkId.toLowerCase(),
          hideControls: true,
          bgColor: { r: 255, g: 255, b: 255 },
          // Expected value is an array with 'expand', 'selection', 'animation' keywords.
          hideCanvasControls: this.hideCanvasControls,
        }
        viewerInstance.render(viewerContainer, options)
        this.canvas = viewerInstance.canvas
        cb()
      }
    },
  },
}
</script>
<template>
  <!-- how not to trigger the click event -->
  <div class="view-structure" @click.prevent="true">
    <action
      v-if="collapsible"
      class="view-structure-link"
      icon-pos="right"
      @click="expandStructure"
    >
      View structure<i
        slot="icon"
        :class="['fas', showStructure ? 'fa-caret-down' : 'fa-caret-right']"
      />
    </action>
    <template v-if="!collapsible || structureShown">
      <div v-show="showStructure">
        <loading v-if="loading" />
        <div
          v-if="linkScheme === 'PDB'"
          ref="pdbViewer"
          :class="[
            'pdb-viewer',
            { 'no-height': loading },
            { 'hide-canvas-controls': hideCanvasControls.length },
          ]"
          @keydown.esc="esc"
        ></div>
        <div v-else-if="chemImageSrc" class="chem-image">
          <img
            :src="chemImageSrc"
            :alt="'Chemistry image for ' + chemImageSrc"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<style lang="scss">
.pdb-viewer {
  button {
    padding: 0 !important;
    min-width: 0 !important;
  }
  &.hide-canvas-controls .msp-viewport-controls-buttons div:nth-child(3) {
    display: none;
  }
  .msp-selection-viewport-controls {
    z-index: 1;
  }
  .msp-plugin .msp-semi-transparent-background {
    opacity: 1;
  }
}
</style>
<style scoped lang="scss">
.view-structure-link {
  display: block;
  margin-top: $base-unit * 1.5;
}
.pdb-viewer {
  position: relative;
  z-index: 4;
  margin-bottom: $base-unit * 4;
  width: $base-unit * 112.5;
  height: $base-unit * 112.5;
  float: left;
  &.no-height {
    height: 0;
  }
}
.chem-image {
  width: $base-unit * 75;
  height: $base-unit * 75;
}
</style>
