var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.imageList.length)?_c('loading'):_c('div',{staticClass:"figures"},[_vm._l((_vm.imageList),function(i){return _c('div',{key:i.name,attrs:{"itemprop":"image","itemscope":"","itemtype":"http://schema.org/ImageObject"}},[_c('meta',{attrs:{"itemprop":"url","content":'https://europepmc.org/articles/' + _vm.abstract.pmcid + '/bin/' + i.name}})])}),_c('div',{class:['figures--bar', { full: _vm.imageList.length === 3 }]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.leftImageIndex !== 0),expression:"leftImageIndex !== 0"}],staticClass:"figure-fade scroll-left"},[_c('div',{staticClass:"figure-scroll-button left",attrs:{"tabindex":"0","role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.scrollFigures(-1)}}},[_c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"far fa-circle fa-stack-1x"}),_c('i',{staticClass:"fas fa-angle-left fa-stack-1x"})]),_c('span',{staticClass:"alt-text"},[_vm._v("Show more figures")])])]),_vm._l((_vm.imageList.slice(
        _vm.leftImageIndex,
        _vm.leftImageIndex + _vm.figureDisplaySize
      )),function(image,index){return _c('a',{key:image.label,class:[
        'figures--figure-box',
        { active: _vm.activeImage && image.label === _vm.activeImage.label },
      ],style:('background-image: url(data:image/jpg;base64,' + image.imageData + ')'),attrs:{"id":'article--figure--icon-' +
        (image.label ? image.label.replace(/[^a-zA-Z\d]/, '-') : index),"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setFullSizeImage(image)}}},[_c('span',{staticClass:"alt-text"},[_vm._v("("+_vm._s(image.label)+") Click for full size image")])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.leftImageIndex + _vm.figureDisplaySize < _vm.imageList.length),expression:"leftImageIndex + figureDisplaySize < imageList.length"}],staticClass:"figure-fade scroll-right"},[_c('div',{staticClass:"figure-scroll-button right",attrs:{"tabindex":"0","role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.scrollFigures(1)}}},[_c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"far fa-circle fa-stack-1x"}),_c('i',{staticClass:"fas fa-angle-right fa-stack-1x"})]),_c('span',{staticClass:"alt-text"},[_vm._v("Show more figures")])])])],2),(_vm.activeImage)?_c('div',{staticClass:"figures--full-size"},[_c('div',[_c('action',{staticClass:"figures--full-size-close",attrs:{"id":"article--figure--fullSizeClose"},on:{"click":function($event){$event.preventDefault();return _vm.closeFullSizeImage.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-times",attrs:{"slot":"icon"},slot:"icon"})])],1),_c('img',{attrs:{"id":"article--figure--fullSize","src":'data:image/jpg;base64,' + _vm.activeImage.imageData,"alt":_vm.activeImage.caption ||
        'An external file that holds a picture, illustration, etc. Object name is ' +
          _vm.activeImage.name}}),(_vm.activeImage.label)?_c('div',{staticClass:"figures--full-size-label semi-bold"},[_vm._v("\n      "+_vm._s(_vm.activeImage.label)+"\n    ")]):_vm._e(),(_vm.activeImage.caption)?_c('div',{staticClass:"figures--full-size-caption small"},[_vm._v("\n      "+_vm._s(_vm.activeImage.caption)+"\n    ")]):_vm._e(),_c('div',{staticClass:"figures--full-size-go"},[_c('action',{directives:[{name:"show",rawName:"v-show",value:(_vm.getActiveImageIndex !== 0),expression:"getActiveImageIndex !== 0"}],on:{"click":function($event){$event.preventDefault();return _vm.goToImage(-1)}}},[_c('i',{staticClass:"fas fa-angle-left",attrs:{"slot":"icon"},slot:"icon"}),_vm._v("Back")]),_c('action',{attrs:{"icon-pos":"right"},on:{"click":function($event){$event.preventDefault();return _vm.showInFulltext.apply(null, arguments)}}},[_vm._v("Show in full text"),_c('i',{staticClass:"far fa-arrow-alt-circle-down",attrs:{"slot":"icon"},slot:"icon"})]),_c('action',{directives:[{name:"show",rawName:"v-show",value:(_vm.getActiveImageIndex !== _vm.imageList.length - 1),expression:"getActiveImageIndex !== imageList.length - 1"}],attrs:{"icon-pos":"right"},on:{"click":function($event){$event.preventDefault();return _vm.goToImage(1)}}},[_vm._v("Next"),_c('i',{staticClass:"fas fa-angle-right",attrs:{"slot":"icon"},slot:"icon"})])],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }