<script>
import { mapState } from 'vuex'

export default {
  computed: mapState('article/abstract', ['abstract']),
}
</script>
<template>
  <ul v-if="abstract.patentDetails.classifierList">
    <li
      v-for="classifier in abstract.patentDetails.classifierList.classifier"
      :key="classifier.hyperlink"
    >
      <a :href="classifier.hyperlink"
        >{{ classifier.classification }} ({{
          classifier.classificationType
        }})</a
      >
    </li>
  </ul>
</template>
