<script>
import { mapState } from 'vuex'
import { scrollTo } from 'epmc-patterns/helpers'
import Annotation from '@/helpers/article/annotation'
import { registerMatomoEvent } from '@/helpers/matomo'
import { Tooltip } from 'epmc-patterns/components/v2'

export default {
  components: {
    Tooltip,
  },
  props: {
    screenWidth: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      unpayWallLink: {},
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('article/sections', ['sections']),
    ...mapState('article/fulltext', ['fulltextHtml']),
    ...mapState('article/fullTextLinks', [
      'fullTextLinks',
      'unpayWallRequestFinished',
    ]),

    getFullTextSecs() {
      const { abstract, fulltextHtml, fullTextLinks } = this
      const secs = []
      if (fulltextHtml) {
        const html = document.createElement('div')
        html.innerHTML = fulltextHtml
        const firstSection =
          html.querySelector('#epmc-fulltext-container') ||
          html.querySelector('.jig-ncbiinpagenav')
        if (firstSection) {
          const list = firstSection.querySelectorAll(
            'h2:not(#fulltext--author-affiliations-title, #fulltext--permissions-title)'
          )
          const listOfHeadingsWithLanguage = []
          // Iterate over each selected h2 element to find the parent div with a lang attribute
          list.forEach((h2Element) => {
            let parentElement = h2Element.parentElement

            // Traverse up the DOM tree until a parent element with a lang attribute is found
            while (parentElement && !parentElement.hasAttribute('lang')) {
              parentElement = parentElement.parentElement
            }

            // Check if a parent element with a lang attribute was found
            if (parentElement) {
              listOfHeadingsWithLanguage.push({
                id: h2Element.id.replace('title', ''),
                lang: parentElement.lang,
              })
            }
          })
          // Select all the h2 elements that are not #fulltext--author-affiliations-title or #fulltext--permissions-title
          for (const item of list) {
            const sec = {}
            if (
              item.innerHTML !== 'Abstract' &&
              (item.id.includes('title') || item.id.includes('_s'))
            ) {
              const text = Annotation.removeAnnotationTags(item.innerHTML)
              sec.id = item.id.replace('title', '')
              sec.title = text.includes('\u2003')
                ? text.endsWith('\u2003')
                  ? text.substring(0, text.lastIndexOf('\u2003'))
                  : text.substring(text.lastIndexOf('\u2003') + 1)
                : text
              if (item.id.includes('ref-list') || item.id.includes('bi')) {
                if (html.querySelector('#reference-list')) {
                  const refList = html.querySelector('#reference-list > ul')
                    ? html.querySelector('#reference-list > ul')
                    : html.querySelector('#reference-list')
                  sec.title += ' (' + refList.childElementCount + ')'
                }
              }
              const headingWithLanguage = listOfHeadingsWithLanguage.find(
                (item) => item.id === sec.id
              )
              if (headingWithLanguage) {
                sec.lang = headingWithLanguage.lang
              }
              secs.push(sec)
            } else if (item.parentElement.id === 'rs') {
              const sec = {}
              sec.title = Annotation.removeAnnotationTags(item.innerHTML)
              sec.id = 'rs'
              secs.push(sec)
            }
          }
        }
        if (fullTextLinks.length > 0 || abstract.doi) {
          secs.push({ id: 'full-text-links', title: 'Full text links' })
        }
      }
      return secs
    },
    getFullTextLink() {
      let fulltextLink = ''
      if (
        this.abstract.fullTextUrlList &&
        this.abstract.fullTextUrlList.fullTextUrl
      ) {
        fulltextLink = this.abstract.fullTextUrlList.fullTextUrl[0].url
      } else if (this.abstract.doi) {
        fulltextLink = '//doi.org/' + this.abstract.doi
      }
      return fulltextLink
    },
  },
  watch: {
    unpayWallRequestFinished() {
      this.updateUnpayWallLink()
    },
  },
  created() {
    if (window.innerWidth <= 799) {
      this.sections.find((sec) => sec.id === 'abstract').inFrame = false
    }
    this.highlightMenu()
    window.addEventListener('scroll', this.highlightMenu)
  },
  destroyed() {
    window.removeEventListener('scroll', this.highlightMenu)
  },
  methods: {
    expand(e, secid, boolean) {
      const sec = this.sections.find((sec) => sec.id === secid)
      sec.visible = boolean
      scrollTo('#' + secid, '#' + secid + ' div:first-child')

      history.pushState(null, null, '#' + secid)
      this.sections.forEach((sec) => {
        sec.inFrame = false
      })
      sec.inFrame = true
      this.registerMatomoEventForLeftNavigation(
        this.sections.find((sec) => sec.id === secid).title
      )
    },
    highlightMenu() {
      const { abstract, getFullTextSecs, sections, $refs } = this
      if (window.innerWidth > 799) {
        if (sections.find((sec) => sec.id === 'free-full-text').inFrame) {
          const test =
            document.getElementById('epmc-fulltext-container') ||
            document.getElementById('fulltextcontent')
          if (test && test.offsetWidth > 0 && test.offsetHeight > 0) {
            const highlightedSec = getFullTextSecs.find((sec) => {
              let secContainer =
                document.getElementById(sec.id) ||
                document.getElementById(sec.id + 'title')
              const hasBook =
                abstract.hasBook === 'Y' || abstract.source === 'NBK'
              if (
                hasBook &&
                secContainer.id.includes(
                  secContainer.parentElement.id.replace(/\./g, '_')
                )
              ) {
                secContainer = secContainer.parentElement
              }
              const pos = secContainer.getBoundingClientRect()
              if (pos.x <= 0) {
                return true
              } else {
                const classes =
                  $refs[sec.id].length > 0 ? $refs[sec.id][0].classList : []
                return (
                  pos.top <= 50 &&
                  pos.bottom >= 20 &&
                  !classes.contains('highlighted')
                )
              }
            })
            if (highlightedSec) {
              const classes = $refs[highlightedSec.id][0].classList
              if (!classes.contains('highlighted')) {
                const highlightedMenuItem = document.querySelector(
                  '#epmc-menu li.highlighted'
                )
                if (highlightedMenuItem) {
                  highlightedMenuItem.classList.remove('highlighted')
                }
                classes.add('highlighted')
              }
            }
          }
        }
      }
    },
    updateUnpayWallLink() {
      if (this.fullTextLinks.length > 0) {
        this.unpayWallLink = this.fullTextLinks.find((link) => {
          return link.hasOwnProperty('is_best')
        })
        if (this.unpayWallLink) return this.unpayWallLink
      }
    },
    goToSection(secId) {
      history.pushState(null, null, '#' + secId)
      this.$nextTick(() => {
        this.highlightMenu()
        scrollTo('#' + secId, '#fulltextcontent')
      })
      this.registerMatomoEventForLeftNavigation(
        this.getFullTextSecs.find((sec) => sec.id === secId).title
      )
    },
    registerMatomoEventForLeftNavigation(sectionTitle) {
      registerMatomoEvent('Article', 'Left navigation', sectionTitle)
      if (sectionTitle === 'Free full text') {
        this.registerMatomoEventForFullTextLink('Europe PMC')
      }
    },
    registerMatomoEventForFullTextLink(linkType) {
      registerMatomoEvent('Article', 'Full text links', linkType)
    },
  },
}
</script>
<template>
  <ul id="epmc-menu" class="article-menu">
    <li
      v-for="section in sections.filter((sect) => sect.render)"
      :key="section.id"
      :class="`${section.inFrame ? 'highlighted' : ''} ${
        section.class ? section.class : ''
      }`"
    >
      <template v-if="section.id === 'full-text-links'">
        <a
          v-if="
            screenWidth < 799 ||
            (fullTextLinks.length + (abstract.doi ? 1 : 0) > 1 &&
              (!unpayWallLink || !unpayWallLink.url))
          "
          :id="'article--side-menu--item-' + section.id"
          :href="'#' + section.id"
          :class="section.id"
          @click.prevent="expand($event, section.id, true)"
          ><span>Full text links</span></a
        >
        <a
          v-else-if="unpayWallLink.url"
          :id="'article--side-menu--item-' + section.id"
          :href="unpayWallLink.url"
          class="link-with-icon"
          @click="registerMatomoEventForFullTextLink('Unpaywall')"
        >
          <tooltip boundaries="#epmc-content"
            ><span slot="trigger">
              Full text<img
                src="@/assets/unpaywall.gif"
                class="icon"
                alt="Unpaywall"
            /></span>
            Read article for free, via Unpaywall (a legal, open copy of the full
            text)
          </tooltip>
        </a>
        <a
          v-else-if="unpayWallRequestFinished"
          :id="'article--side-menu--item-' + section.id"
          class="full-text-link"
          :href="
            fullTextLinks.length > 0
              ? fullTextLinks[0].url
              : abstract.doi
              ? '//doi.org/' + abstract.doi
              : ''
          "
          @click="registerMatomoEventForFullTextLink('External')"
          ><tooltip boundaries="#epmc-content"
            ><span slot="trigger"
              >Full text<i class="fas fa-external-link-alt" /></span
            >Available from publisher site using DOI. A subscription may be
            required.</tooltip
          ></a
        >
        <a v-else class="link-with-icon" disabled
          ><span
            >Full text<img
              src="@/assets/menu-item-loading-icon.gif"
              alt="Full text" /></span
        ></a>
      </template>
      <template v-else>
        <a
          :id="'article--side-menu--item-' + section.id"
          :href="'#' + section.id"
          :class="section.id"
          @click.prevent="
            expand(
              $event,
              section.id,
              section.id === 'free-full-text' && !screenWidth
                ? !section.visible
                : true
            )
          "
          ><span
            >{{ section.title }}
            <i
              v-if="section.id === 'free-full-text' && !screenWidth"
              :class="[
                'fas icon',
                section.visible ? 'fa-caret-down' : 'fa-caret-right',
              ]"
          /></span>
        </a>
        <ul
          v-if="section.id === 'free-full-text' && !screenWidth"
          v-show="section.visible"
          id="full-text-menu"
        >
          <div
            v-for="sec in getFullTextSecs"
            :key="sec.id"
            :lang="sec.lang"
            class="tsec sec"
          >
            <li :ref="sec.id" :class="sec.id">
              <a
                :id="
                  'article--side-menu--full-text--item-' +
                  sec.id.replace(/[^a-zA-Z\d]/g, '-')
                "
                :href="'#' + sec.id"
                @click.prevent="goToSection(sec.id)"
              >
                <span v-html="sec.title" />
              </a>
            </li>
          </div>
        </ul>
      </template>
    </li>
  </ul>
</template>
<style scoped lang="scss">
.article-menu {
  list-style-type: none;
  margin: ($base-unit * 8.5) 0;
  padding: 0 0 $base-unit;
  li {
    margin: 0;

    & > a {
      display: block;
      margin: 0;
      padding: $base-unit 0 $base-unit ($base-unit * 4);
      border-left: $base-unit solid transparent;
      font-weight: 600;
      text-align: left;

      &:link,
      &:visited {
        color: $epmc-darker-blue;
      }
      &:hover {
        color: $epmc-darker-grey;
      }

      &.link-with-icon {
        * {
          vertical-align: middle;
        }

        img {
          width: $base-unit * 3.5;
        }
      }
      &.link-with-icon,
      &.full-text-link {
        img,
        i {
          margin-left: $base-unit;
        }
      }
    }
    &.highlighted > a {
      color: $epmc-darker-grey;
      border-left: $base-unit solid $epmc-light-blue;
      border-left: $base-unit solid $epmc-light-blue;
      padding: ($base-unit * 1.5) 0 ($base-unit * 1.5) ($base-unit * 4);
      margin: -($base-unit/2) 0;
    }
  }

  li.data-section {
    &:before {
      display: block;
      content: '';
      margin: ($base-unit * 2) 0 ($base-unit * 2) ($base-unit * 4);
      box-sizing: border-box;
      border-bottom: thin solid lighten($epmc-darker-grey, 50%);
      cursor: auto;
      @media screen and (max-width: $breakpoint-extra-small) {
        display: none;
      }
    }
    & ~ li.data-section {
      &:before {
        display: none;
      }
    }
  }

  @media screen and (max-width: $breakpoint-extra-small) {
    margin: 0 auto;
    padding-bottom: 0;
    text-align: left;
    width: 100%;
    li,
    li.highlighted {
      display: block;
      width: 100%;
      border-bottom: thin solid lighten($epmc-darker-grey, 50%);
      & > a {
        display: block;
        width: 100%;
        border: 0;
        margin: 0;
        padding: 0;
        color: $epmc-darker-blue;
        background-color: $epmc-white;
        font-weight: 600;
        text-decoration: none;
        & > span {
          display: block;
          width: $width-x;
          margin: 0 auto;
          padding-top: $base-unit * 2.5;
          padding-bottom: $base-unit * 2.5;
        }
      }
      i {
        margin-left: $base-unit;
        vertical-align: -1px;
      }
    }
  }
}

#full-text-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li a {
    text-transform: none;
  }

  li > a {
    padding-left: $base-unit * 7.5;
  }
  @media screen and (max-width: $breakpoint-small) {
    display: none;
  }
}
</style>
