var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"article-menu",attrs:{"id":"epmc-menu"}},_vm._l((_vm.sections.filter((sect) => sect.render)),function(section){return _c('li',{key:section.id,class:`${section.inFrame ? 'highlighted' : ''} ${
      section.class ? section.class : ''
    }`},[(section.id === 'full-text-links')?[(
          _vm.screenWidth < 799 ||
          (_vm.fullTextLinks.length + (_vm.abstract.doi ? 1 : 0) > 1 &&
            (!_vm.unpayWallLink || !_vm.unpayWallLink.url))
        )?_c('a',{class:section.id,attrs:{"id":'article--side-menu--item-' + section.id,"href":'#' + section.id},on:{"click":function($event){$event.preventDefault();return _vm.expand($event, section.id, true)}}},[_c('span',[_vm._v("Full text links")])]):(_vm.unpayWallLink.url)?_c('a',{staticClass:"link-with-icon",attrs:{"id":'article--side-menu--item-' + section.id,"href":_vm.unpayWallLink.url},on:{"click":function($event){return _vm.registerMatomoEventForFullTextLink('Unpaywall')}}},[_c('tooltip',{attrs:{"boundaries":"#epmc-content"}},[_c('span',{attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v("\n            Full text"),_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/unpaywall.gif"),"alt":"Unpaywall"}})]),_vm._v("\n          Read article for free, via Unpaywall (a legal, open copy of the full\n          text)\n        ")])],1):(_vm.unpayWallRequestFinished)?_c('a',{staticClass:"full-text-link",attrs:{"id":'article--side-menu--item-' + section.id,"href":_vm.fullTextLinks.length > 0
            ? _vm.fullTextLinks[0].url
            : _vm.abstract.doi
            ? '//doi.org/' + _vm.abstract.doi
            : ''},on:{"click":function($event){return _vm.registerMatomoEventForFullTextLink('External')}}},[_c('tooltip',{attrs:{"boundaries":"#epmc-content"}},[_c('span',{attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v("Full text"),_c('i',{staticClass:"fas fa-external-link-alt"})]),_vm._v("Available from publisher site using DOI. A subscription may be\n          required.")])],1):_c('a',{staticClass:"link-with-icon",attrs:{"disabled":""}},[_vm._m(0,true)])]:[_c('a',{class:section.id,attrs:{"id":'article--side-menu--item-' + section.id,"href":'#' + section.id},on:{"click":function($event){$event.preventDefault();return _vm.expand(
            $event,
            section.id,
            section.id === 'free-full-text' && !_vm.screenWidth
              ? !section.visible
              : true
          )}}},[_c('span',[_vm._v(_vm._s(section.title)+"\n          "),(section.id === 'free-full-text' && !_vm.screenWidth)?_c('i',{class:[
              'fas icon',
              section.visible ? 'fa-caret-down' : 'fa-caret-right',
            ]}):_vm._e()])]),(section.id === 'free-full-text' && !_vm.screenWidth)?_c('ul',{directives:[{name:"show",rawName:"v-show",value:(section.visible),expression:"section.visible"}],attrs:{"id":"full-text-menu"}},_vm._l((_vm.getFullTextSecs),function(sec){return _c('div',{key:sec.id,staticClass:"tsec sec",attrs:{"lang":sec.lang}},[_c('li',{ref:sec.id,refInFor:true,class:sec.id},[_c('a',{attrs:{"id":'article--side-menu--full-text--item-' +
                sec.id.replace(/[^a-zA-Z\d]/g, '-'),"href":'#' + sec.id},on:{"click":function($event){$event.preventDefault();return _vm.goToSection(sec.id)}}},[_c('span',{domProps:{"innerHTML":_vm._s(sec.title)}})])])])}),0):_vm._e()]],2)}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("Full text"),_c('img',{attrs:{"src":require("@/assets/menu-item-loading-icon.gif"),"alt":"Full text"}})])
}]

export { render, staticRenderFns }