var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"large",attrs:{"close-when-clicking-outside":true},on:{"close":_vm.closeMetadataAuthorPopup}},[_c('template',{slot:"title"},[_vm._v("\n    "+_vm._s(_vm.authorName)+"\n  ")]),_c('div',{staticClass:"author-popup-content"},[_c('ul',{staticClass:"author-popup-links"},[(_vm.getAllEmailsFromAuthorAff)?_vm._l((_vm.getAllEmailsFromAuthorAff),function(email){return _c('li',{key:email},[_c('action',{attrs:{"data-email":""},on:{"click":function($event){return _vm.sendEmail(email)}}},[_c('i',{staticClass:"fas fa-envelope",attrs:{"slot":"icon"},slot:"icon"}),_c('span',{staticClass:"email"},[_vm._v(_vm._s(email.split().reverse().join('')))])])],1)}):_vm._e(),(_vm.author.authorId)?[_c('li',{staticClass:"author-popup-link-item"},[_c('action',{attrs:{"to":{
              name: 'authorProfile',
              params: { orcid: _vm.author.authorId.value },
            },"itemprop":"mainEntityOfPage"},on:{"click":function($event){return _vm.registerMatomoEventForAuthorPopup('Author Profile')}}},[_c('i',{staticClass:"fas fa-user",attrs:{"slot":"icon"},slot:"icon"}),_vm._v("Author profile\n          ")])],1),_c('li',{staticClass:"author-popup-link-item"},[_c('action',{attrs:{"to":{
              name: 'search',
              query: { query: 'AUTHORID:' + _vm.author.authorId.value },
            }},on:{"click":function($event){return _vm.registerMatomoEventForAuthorPopup('Search articles by ORCID')}}},[_c('i',{staticClass:"fas fa-search",attrs:{"slot":"icon"},slot:"icon"}),_vm._v("Search for articles linked\n            to Author's ORCID\n          ")])],1),_c('li',{staticClass:"author-popup-link-item"},[_c('action',{attrs:{"href":'//orcid.org/' + _vm.author.authorId.value},on:{"click":function($event){return _vm.registerMatomoEventForAuthorPopup('External ORCID')}}},[_c('i',{staticClass:"fab fa-orcid",attrs:{"slot":"icon"},slot:"icon"}),_vm._v(_vm._s(_vm.author.authorId.value)),_c('i',{staticClass:"fas fa-external-link-alt"})])],1)]:[_c('li',{staticClass:"author-popup-link-item"},[_c('action',{attrs:{"to":_vm.getAuthorSearchLink}},[_c('i',{staticClass:"fas fa-search",attrs:{"slot":"icon"},slot:"icon"}),_c('span',[_vm._v("Search articles by '"+_vm._s(_vm.authorName)+"'")])])],1)],(
          _vm.author.authorAffiliationDetailsList &&
          _vm.author.authorAffiliationDetailsList.authorAffiliation &&
          _vm.author.authorAffiliationDetailsList.authorAffiliation.length > 0
        )?[_c('h6',{staticStyle:{"font-style":"normal"}},[_vm._v("\n          Affiliation"+_vm._s(_vm.author.authorAffiliationDetailsList.authorAffiliation.length > 1
              ? 's'
              : '')+"\n        ")]),_c('ul',{staticClass:"author-affiliations"},_vm._l((_vm.showAllAffiliations
              ? _vm.author.authorAffiliationDetailsList.authorAffiliation
              : _vm.author.authorAffiliationDetailsList.authorAffiliation.slice(
                  0,
                  5
                )),function(aff,index){return _c('li',{key:`${_vm.author.affiliation}-${index}`},[_c('b',[_vm._v(_vm._s(index + 1)+".")]),_vm._v("\n            "+_vm._s(_vm.removeEmailFromAffiliation(aff.affiliation))+"\n          ")])}),0),(
            _vm.author.authorAffiliationDetailsList.authorAffiliation.length > 5
          )?[_c('a',{staticStyle:{"font-weight":"bold"},on:{"click":function($event){$event.preventDefault();_vm.showAllAffiliations = !_vm.showAllAffiliations}}},[(_vm.showAllAffiliations)?_c('span',[_vm._v(" Show less ")]):_c('span',[_vm._v("\n              Show all ("+_vm._s(_vm.author.authorAffiliationDetailsList.authorAffiliation.length)+")\n            ")]),_c('i',{class:[
                'fas',
                _vm.showAllAffiliations ? 'fa-caret-down' : 'fa-caret-right',
              ],attrs:{"slot":"icon"},slot:"icon"})])]:_vm._e()]:_vm._e()],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }