var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('div',{staticClass:"metadata--author-block",attrs:{"slot":"trigger","itemprop":"author","itemscope":"","itemtype":"http://schema.org/Person"},slot:"trigger"},[_c('a',{class:['metadata--author-link'],attrs:{"id":`article--author--name-${
        _vm.author.authorId
          ? _vm.author.authorId.value
          : _vm.authorName.replace(/[^a-zA-Z]/g, '-')
      }`},on:{"click":function($event){$event.preventDefault();return _vm.openMetadataAuthorPopup()}}},[_c('span',{staticClass:"metadata--author-name",attrs:{"itemprop":"name"}},[_vm._v(_vm._s(_vm.shortAuthorName))]),_vm._v(_vm._s(' ')),(_vm.author.affiliation)?[_c('sup',_vm._l((_vm.affiliations),function(affiliation,index){return _c('span',{key:index,staticClass:"affiliation-reference"},[(
                _vm.author.authorAffiliationDetailsList.authorAffiliation.find(
                  (item) => _vm.stripEmail(item.affiliation) === affiliation.name
                )
              )?_c('span',{staticClass:"book-tooltip"},[_vm._v("\n              "+_vm._s(index + 1)+_vm._s(_vm.getAffiliationsByAuthor(index, _vm.author))+"\n            ")]):_vm._e()])}),0),(_vm.containEmail(_vm.author.affiliation))?_c('i',{staticClass:"fas fa-envelope"}):_vm._e()]:_vm._e(),(_vm.author.authorId)?_c('i',{staticClass:"fab fa-orcid"}):_vm._e()],2),(!_vm.last)?_c('span',[_vm._v(", ")]):_vm._e()]),(_vm.showMetadataAuthorPopup)?_c('metadata-author-popup',{attrs:{"author":_vm.author,"author-name":_vm.authorName},on:{"close":function($event){_vm.showMetadataAuthorPopup = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }