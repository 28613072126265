<script>
import { mapState } from 'vuex'

export default {
  computed: mapState('article/abstract', ['abstract']),
}
</script>
<template>
  <ul v-if="abstract.patentDetails.priorityList">
    <li
      v-for="priority in abstract.patentDetails.priorityList.priority"
      :key="priority.priorityNumber"
    >
      {{ priority.priorityNumber }}
    </li>
  </ul>
</template>
