<script>
import { mapState, mapActions } from 'vuex'
import { formatDoi } from '@/helpers/doi'

export default {
  filters: {
    linkText(url) {
      const labels = {
        F: 'Free to read',
        F2: 'Free to read after 2 months',
        F4: 'Free after 4 months',
        F6: 'Free after 6 months',
        F12: 'Free after 12 months',
        F18: 'Free after 18 months',
        F24: 'Free after 24 months',
        F36: 'Free to read after 36 months',
        OA: 'Open access',
        U: 'Open access',
        S: 'Subscription required',
      }

      const sites = {
        'ethos.bl.uk': 'EThOS',
        'hdl.handle.net': 'Handle.Net',
        'www.dh.gov.uk': 'Department of Health',
      }
      const site = sites[url.site] || url.site

      return labels[url.availabilityCode] + ' at ' + site
    },
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('article/fullTextLinks', ['fullTextLinks']),
    ...mapState('article/dataLinks', ['dataLinks']),
    unpayWallLink() {
      const { fullTextLinks } = this

      let unpayWallLink = null
      if (fullTextLinks.length > 0) {
        unpayWallLink = fullTextLinks.find((link) => {
          return link.hasOwnProperty('is_best')
        })
      }
      return unpayWallLink
    },
    otherLinks() {
      return this.fullTextLinks.filter(
        (link) => !link.hasOwnProperty('is_best')
      )
    },
    formatDoi() {
      return formatDoi(this.abstract.doi)
    },
  },
  async created() {
    // better to level the api calls up
    this.loadFullTextLinks()
    //this.loadUnpayWallLinks()
    await this.loadDataLinks()
    if (this.dataLinks['full_text'])
      this.fullTextLinks.push(...this.dataLinks['full_text'])
  },
  methods: {
    ...mapActions('article/fullTextLinks', ['loadFullTextLinks']),
    ...mapActions('article/dataLinks', ['loadDataLinks']),
  },
}
</script>
<template>
  <div class="full-text-link-content">
    <p v-if="abstract.doi">
      Read article at publisher's site:
      <a
        id="article--doi--link-fulltextlinks"
        :href="'//doi.org/' + abstract.doi"
        >{{ formatDoi }}<i class="fas fa-external-link-alt"
      /></a>
    </p>
    <p v-if="otherLinks.find(({ site }) => site === 'Unpaywall')">
      Read article for free, from open access legal sources, via Unpaywall:
      <a :href="otherLinks.find(({ site }) => site === 'Unpaywall').url">
        {{ otherLinks.find(({ site }) => site === 'Unpaywall').url
        }}<img src="@/assets/unpaywall.gif" alt="Unpaywall"
      /></a>
    </p>

    <template
      v-for="(fullTextUrl, index) in otherLinks.filter(
        ({ site }) => site !== 'Unpaywall'
      )"
    >
      <p v-if="fullTextUrl.name" :key="fullTextUrl.name">
        {{ fullTextUrl.name }}
        <template v-for="link in fullTextUrl.links">
          <br :key="link.url" /><a :key="link.url" :href="link.url"
            >{{ link.url }}<i class="fas fa-external-link-alt"
          /></a>
        </template>
      </p>
      <p v-else :key="index">
        {{ fullTextUrl | linkText }}<br /><a :href="fullTextUrl.url"
          >{{ fullTextUrl.url }}<i class="fas fa-external-link-alt"
        /></a>
      </p>
    </template>
  </div>
</template>
<style lang="scss">
.full-text-link-content {
  img {
    width: $base-unit * 3.5;
    vertical-align: middle;
  }
  img,
  i {
    margin-left: $base-unit;
  }
}
</style>
