<script>
import { fetchGrants, fetchGrant } from '@/api'

export default {
  props: {
    grantId: {
      type: String,
      default: '',
    },
    funderAgency: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      familyName: '',
      givenName: '',
      personTitle: '',
      grantTitle: '',
      institution: '',
      publicationsCount: '',
      countsLoaded: false,
    }
  },
  computed: {
    grantTitleQuery() {
      const { grantId, familyName, personInitial, funderAgency } = this
      return (
        'pi:"' +
        familyName +
        ' ' +
        personInitial +
        '" gid:"' +
        grantId +
        '" ga:"' +
        funderAgency +
        '"'
      )
    },
    grantTitleLink() {
      return {
        name: 'grantDetails',
        query: {
          query: this.grantTitleQuery,
        },
      }
    },
  },
  created() {
    let record = {}
    const { grantId, funderAgency } = this
    fetchGrants({ grantId, funderAgency }).then((response) => {
      if (response.RecordList && response.RecordList !== null) {
        if (Array.isArray(response.RecordList.Record)) {
          record = response.RecordList.Record[0]
        } else {
          record = response.RecordList.Record
        }
        this.familyName = record.Person ? record.Person.FamilyName : ''
        this.givenName = record.Person ? record.Person.GivenName : ''
        this.personTitle = record.Person ? record.Person.Title : ''
        this.personInitial = record.Person ? record.Person.Initials : ''
        this.grantTitle = record.Grant ? record.Grant.Title : ''
        this.institution = record.Institution ? record.Institution.Name : ''
      }
    })
    fetchGrant(grantId, funderAgency).then((response) => {
      this.publicationsCount = response.hitCount
      this.countsLoaded = true
    })
  },
}
</script>
<template>
  <li class="funding-grant">
    <template v-if="grantTitle">
      <h4 class="funding-grant-title small">
        <router-link
          :href="'/grantfinder/grantdetails?query=' + grantTitleQuery"
          :to="grantTitleLink"
          >{{ grantTitle }}</router-link
        >
      </h4>
      <p v-if="familyName">
        {{ personTitle }} {{ givenName }} {{ familyName
        }}{{ institution ? ', ' + institution : '' }}
      </p>
    </template>
    <p>Grant ID: {{ grantId }}</p>
    <p class="funding-grant-link">
      <template v-if="countsLoaded">
        <router-link
          v-if="publicationsCount > 0"
          :to="{
            name: 'search',
            query: {
              query:
                'GRANT_AGENCY_ID:&quot;' +
                grantId +
                '_agency_' +
                funderAgency +
                '&quot;',
            },
          }"
        >
          {{ publicationsCount }} publication<span v-if="publicationsCount > 1"
            >s</span
          >
        </router-link>
        <template v-else>0 publications</template>
      </template>
      <template v-else>
        <img src="@/assets/menu-item-loading-icon.gif" alt="Loading" />
        publications
      </template>
    </p>
  </li>
</template>
<style scoped lang="scss">
.funding-grant {
  margin: $base-unit * 2.5;
  h4,
  p {
    margin: 0;
  }
  img {
    height: $base-unit * 3.5;
  }
}
</style>
