<script>
import { mapState, mapActions } from 'vuex'
import TextAnnotator from 'text-annotator'
import { Notification } from 'epmc-patterns/components/v2'

export default {
  components: { Notification },
  data() {
    return {
      warnings: [],
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('searchSessions', ['snippets']),
    abstractText() {
      return this.abstract.abstractText
        ? this.abstract.abstractText
        : 'No abstract provided.'
    },
    absSnippets() {
      let snippets = []
      if (this.snippets) {
        const s =
          this.snippets[
            this.abstract.source.toLowerCase() + this.abstract.id.toLowerCase()
          ]
        if (s && s.length === 2) {
          const similarity = TextAnnotator.getSimilarity(s[0], s[1])
          if (similarity > 0.97) {
            s.shift()
          }
        }
        snippets = [...new Set(s)]
      }
      return snippets
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.absSnippets && this.absSnippets.length > 0) {
        if (this.abstract.hasFulltext) {
          this.absSnippets.forEach((snip, index) => {
            this.$set(this.warnings, index, false)
          })
        } else {
          this.absSnippets.forEach(async (snip, index) => {
            await this.findSnippet(snip, index, 'abstractHighlighter')
          })
        }
      }
    })
  },
  methods: {
    ...mapActions('article/textHighlight', ['searchAndHighlight']),
    checkFulltext(snippet, index) {
      this.findSnippet(snippet, index, 'fulltextHighlighter').then(
        (highlightIndex) => {
          this.$set(this.warnings, index, highlightIndex === -1)
        }
      )
    },
    async findSnippet(snippet, id, highlighter) {
      const temp = document.createElement('div')
      temp.innerHTML = snippet
      temp.textContent = temp.textContent.trim()

      const highlightIndex = await this.searchAndHighlight({
        highlighter,
        id,
        str: temp.textContent,
        options: {
          highlightOptions: {
            highlightClass: 'snippet-highlight',
          },
        },
      })
      return Promise.resolve(highlightIndex)
    },
  },
}
</script>
<template>
  <div>
    <div
      id="article--abstract--content"
      itemprop="description"
      class="abstract"
      v-html="abstractText"
    />

    <div
      v-if="absSnippets && absSnippets.length > 0 && abstract.hasFulltext"
      id="article--snippets--section"
      class="snippets"
    >
      <h3>Occurrences of search terms within full text</h3>
      <ol>
        <li v-for="(snippet, index) in absSnippets" :key="index">
          <a
            :id="'article--snippets--link-' + index"
            href="#"
            @click.prevent="checkFulltext(snippet, index)"
          >
            ...
            <span v-html="snippet" />...
          </a>
          <notification v-if="warnings[index]" notification-style="warning">
            The snippet could not be located in the article text. This may be
            because the snippet appears in a figure legend, contains special
            characters or spans different sections of the article.
          </notification>
        </li>
      </ol>
    </div>
  </div>
</template>
<style lang="scss">
.abstract {
  line-height: $base-unit * 6;
  margin: ($base-unit * 2) auto;
}
.snippets {
  a:link,
  a:visited {
    font-weight: 400;
  }
  em {
    font-style: normal;
    font-weight: bold;
  }
}
.snippet-highlight {
  cursor: pointer;
  background-color: rgba(248, 231, 28, 0.4);
}
</style>
